
import { defineComponent, ref, PropType } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import Button from "primevue/button";
import Audit from "@/models/audits/Audit";
import { useI18n } from "vue-i18n";
import moment from 'moment'

export default defineComponent({
  components: {
    Card,
    DataTable,
    Column,
    Button,
  },
  props: {
    audits: {
      type: Array as PropType<Audit[]>
    }
  },
  setup(props) {
    const i18n = useI18n();
    return {
      i18n,
      moment
    };
  },
});
