import User from '@/models/users/User'
import Action from '@/models/actions/Action'
import { HttpClient } from '@/http-client'

class UsersApiClient {
    urls = {
        getToken: process.env.VUE_APP_API_URL + '/api-token-auth',
        resetPassword: process.env.VUE_APP_API_URL + '/api/users/$(email)/resetpassword',
        changePassword: process.env.VUE_APP_API_URL + '/api/actions/$(actionId)/run',
        simulateUser: process.env.VUE_APP_API_URL + '/api/users/$(userId)/simulate'
    }

    getToken(username: string, password: string): Promise<User> {
        const getParameters = {
            url: this.urls.getToken,
            requiresToken: false,
            payload: {username: username, password: password}
        }
        return HttpClient.post<User>(getParameters)
    }

    resetPassword(email: string): Promise<User> {
        const getParameters = {
            url: this.urls.resetPassword.replace('$(email)', email),
            requiresToken: false,
        }
        return HttpClient.post<User>(getParameters)
    }

    changePassword(action: Action): Promise<User> {
        const getParameters = {
            url: this.urls.changePassword.replace('$(actionId)', action.id),
            requiresToken: false,
            payload: {action: action}
        }
        return HttpClient.put<User>(getParameters)
    }

    simulateUser(userId: string): Promise<User> {
        const getParameters = {
            url: this.urls.simulateUser.replace('$(userId)', userId),
            requiresToken: false
        }
        return HttpClient.get<User>(getParameters)
    }
}

export default new UsersApiClient