import Feature from '@/models/features/Feature'
import { HttpClient } from '@/http-client'

class FeaturesApiClient {
    urls = {
        getFeatures: process.env.VUE_APP_API_URL + '/api/features',
        getFeature: process.env.VUE_APP_API_URL + '/api/features/$(featureId)',
        postFeature: process.env.VUE_APP_API_URL + '/api/features',
        putFeature: process.env.VUE_APP_API_URL + '/api/features/$(featureId)',
        deleteFeature: process.env.VUE_APP_API_URL + '/api/features/$(featureId)'
    }

    getFeatures(apiFilters?: any): Promise<Feature[]> {
        const getParameters = {
            url: this.urls.getFeatures,
            filters: apiFilters,
        }
        return HttpClient.get<Feature[]>(getParameters)
    }

    getFeature(feature: Feature): Promise<Feature> {
        const getParameters = {
            url: this.urls.getFeature.replace('$(featureId)', feature.id!)
        }
        return HttpClient.get<Feature>(getParameters)
    }

    sendFeature(_feature: Feature): Promise<Feature> {
        const feature = Object.assign({}, _feature);
        if (feature.id != 'notSet') {
            const getParameters = {
                url: this.urls.putFeature.replace('$(featureId)', feature.id!),
                payload: feature,
            }
            return HttpClient.put<Feature>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postFeature,
                payload: feature,
            }
            return HttpClient.post<Feature>(getParameters)
        }
    }

    deleteFeature(feature: Feature): Promise<Feature> {
        const getParameters = {
            url: this.urls.deleteFeature.replace('$(featureId)', feature.id!),
        }
        return HttpClient.delete<Feature>(getParameters)
    }
}

export default new FeaturesApiClient;