
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import RobotTestForm from '@/components/robots/RobotTestForm.vue'

export default defineComponent({
  name: "RobotTestPage",
  components: {
    RobotTestForm
  },
  setup() {
    const route = useRoute();
    const robotId = route.params.robotId =='null' ? null : route.params.robotId;

    return {
      robotId
    };
  },
});
