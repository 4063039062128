
import { defineComponent } from "vue";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";


export default defineComponent({
  name: "AppVersion",
  components: {
    Card,
  },
  setup() {
    const i18n = useI18n();

    
    return {
      i18n
    };
  },
});
