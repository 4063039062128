
import { defineComponent, ref, onMounted, computed } from "vue";
import Utilisateur from "@/models/utilisateurs/Utilisateur";
import apiClient from "@/api-client";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import { useUsersStore } from "@/store/users";
import UtilisateursList from "@/components/utilisateurs/UtilisateursList.vue";

export default defineComponent({
  name: "UtilisateursPage",
  components: {
    Card,
    UtilisateursList,
  },
  setup() {
    const i18n = useI18n();
    const utilisateurs = ref<Utilisateur[]>();
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });

    return {
      utilisateurs,
      i18n,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
    };
  },
});
