import { createVNode as _createVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("label", {
    role: "radio",
    class: `locale-radio ${_ctx.localeInfo.selected ? 'selected' : ''}`.trim()
  }, [
    _createVNode("i", {
      class: `flag-icons ${_ctx.localeInfo.flag}`
    }, null, 2),
    _withDirectives(_createVNode("input", {
      type: "radio",
      class: "icon-button",
      name: "locale",
      value: _ctx.localeInfo.selected,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localeInfo.selected = $event)),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, null, 8, ["value"]), [
      [_vModelRadio, _ctx.localeInfo.selected]
    ])
  ], 2))
}