
import { computed, defineComponent, ref } from "vue";
import Feature from '@/models/features/Feature';
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import Button from "primevue/button";
import { useUsersStore } from "@/store/users";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";

export default defineComponent({
  name: "FeaturesList",
  components: {
    Button,
    DataTable,
    Column,
  },
  props: {
    uuid: {
      type: String,
    },
  },
  setup() {
    const i18n = useI18n();
    const features = ref<Feature[]>();
    const loading = ref(true);
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });
   
    loading.value = true;
    apiClient.features.getFeatures().then((data: Feature[]) => {
      features.value = data.map((feature) => Object.assign(new Feature(), feature));
      loading.value = false;
    });

    return {
      i18n,
      features,
      loading,
      can: usersStore.can,
      appUser,
      moment,
    };
  },
});
