
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import GroupForm from '@/components/groups/GroupForm.vue'

export default defineComponent({
  name: "GroupDetailPage",
  components: {
    GroupForm
  },
  setup() {
    const route = useRoute();
    const groupId = route.params.groupId =='null' ? null : route.params.groupId;

    return {
      groupId,
    };
  },
});
