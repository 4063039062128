
import { defineComponent, ref, computed, watch } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import { useToast } from "primevue/usetoast";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import apiClient from "@/api-client";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LoginPage",
  components: {
    InputText,
    Button,
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const usersStore = useUsersStore();
    const toast = useToast();
    const i18n = useI18n();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const loading = computed(() => {
      return usersStore.state.loading;
    });

    const onClick = () => {
      usersStore.action(MutationType.users.loadAppUser, {
        username: username.value,
        password: password.value,
      });
    };

    const resetPassword = () => {
      if(!username.value) {
        toast.add({
          severity: "warn",
          summary: i18n.t("user.resetpassword.toastEmptyMail.summary"),
          detail: i18n.t("user.resetpassword.toastEmptyMail.detail"),
          life: 5000,
        });

        return;
      }

      apiClient.users.resetPassword(username.value).then(() => {
        toast.add({
          severity: "info",
          summary: i18n.t("user.resetpassword.toast.summary"),
          detail: i18n.t("user.resetpassword.toast.detail"),
          life: 5000,
        });
      });
    };

    return {
      appUser,
      loading,
      username,
      password,
      onClick,
      resetPassword,
      i18n,
    };
  },
});
