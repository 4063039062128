
  import { defineComponent } from 'vue'
  //import { useI18n } from 'vue-i18n'
  //import { LocaleInfoInterface } from '@/models/localization/LocaleInfo.interface'
  //import { i18n } from '@/plugins/vue-i18n-next-plugin'

  export default defineComponent({
    props: {
      localeInfo: {
        type: Object
      }
    },
    emits: ['clicked'],
    setup(props, { emit }) {
      //const i18n = useI18n()

      const onClick = () => {
        emit('clicked', props.localeInfo)
      }

      return {
        onClick
      }
    }
  })
