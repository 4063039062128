import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_ConfirmDialog),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }))
  ], 64))
}