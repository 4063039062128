
import { computed, defineComponent, ref } from "vue";
import Robot from '@/models/robots/Robot';
import Market from '@/models/markets/Market';
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import Button from "primevue/button";
import { useUsersStore } from "@/store/users";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";
import router from "@/router";
import RobotFeature from "@/models/robots/RobotFeature";

export default defineComponent({
  name: "RobotsList",
  components: {
    Button,
    DataTable,
    Column,
  },
  props: {
    uuid: {
      type: String,
    },
  },
  setup() {
    const i18n = useI18n();
    const robots = ref<Robot[]>();
    const loading = ref(true);
    const usersStore = useUsersStore();
    const market = ref<Market>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });
   
    loading.value = true;
    apiClient.robots.getRobots().then((data: Robot[]) => {
      robots.value = data.map((robot) => new Robot(robot));
      loading.value = false;
    });

    const clone = (id: any) => {
      console.log(id);
      const robot: Robot = new Robot();
      robot.id = id;
      apiClient.robots.getRobot(robot).then((robot: Robot) => {
        market.value = new Market();
        market.value.id = robot.training_market;
        apiClient.markets.getMarket(market.value!).then((data: Market) => {
          market.value = Object.assign(new Market(), data);

          robot.name = robot.name + " - CLONE";
          robot.id = "notSet";
          robot.no_gen_en_cours = 0;
          robot.status = 'Nouveau';
          robot.training_result = '';
          robot.gener_models = '';
          robot.gener_prod = '';
          robot.valid_date_fin = moment(new Date()).subtract(1, 'year').toDate();
          robot.training_date_fin = moment(robot.valid_date_fin).subtract(1, 'year').toDate();
          apiClient.robots.sendRobot(robot).then((newRobot: Robot) => {
            // Clonage des features attachées
            apiClient.robots.getRobotFeatures({robot: id}).then((robotFeatures: RobotFeature[]) => {
              for (const robotFeature of robotFeatures) {
                robotFeature.id = "notSet";
                robotFeature.robot = newRobot.id;
                apiClient.robots.sendRobotFeature(robotFeature);
              }
              router.go(0);
            });
          });
        });
      });
    }

    return {
      i18n,
      robots,
      loading,
      can: usersStore.can,
      appUser,
      moment,
      clone,
    };
  },
});
