import { rootStore, dispatchModuleAction } from '../root'
import { MutationType, StoreModuleNames, LocalesStateInterface } from '@/models/store'

const localesStore = {
    get state(): LocalesStateInterface {
        return rootStore.state.localesState
    },
    action<T>(actionName: string, params?: T): void {
        dispatchModuleAction(StoreModuleNames.localesState, actionName, params)
    }
}
// export our wrapper using the composition API convention (i.e. useXYZ)
export const useLocalesStore = () => {
    return localesStore
}