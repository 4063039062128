import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "robots-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")
  const _component_Button = _resolveComponent("Button")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, { value: _ctx.robots }, {
      default: _withCtx(() => [
        (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              field: "id",
              header: _ctx.i18n.t('robots.id')
            }, null, 8, ["header"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.i18n.t('robots.name')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "description",
          header: _ctx.i18n.t('robots.description')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "date_create",
          header: _ctx.i18n.t('robots.date_create')
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.moment(slotProps.data.date_create).format("DD/MM/YYYY")), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.i18n.t('robots.training_market')
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.training_market?.name) + " @" + _toDisplayString(slotProps.data.training_market?.exchange.name), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "status",
          header: _ctx.i18n.t('robots.status')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          header: "",
          style: {"width":"50px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_router_link, {
              to: {
              name: 'RobotDetailPage',
              params: { robotId: slotProps.data.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: _ctx.i18n.t('app.open'),
                  class: "p-button-success",
                  icon: "pi pi-folder-open",
                  iconPos: "right"
                }, null, 8, ["label"])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "",
          style: {"width":"50px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_Button, {
              onClick: ($event: any) => (_ctx.clone(slotProps.data.id)),
              label: _ctx.i18n.t('robots.clone'),
              icon: "pi pi-clone",
              iconPos: "right"
            }, null, 8, ["onClick", "label"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "",
          style: {"width":"50px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_router_link, {
              to: {
              name: 'RobotTestPage',
              params: { robotId: slotProps.data.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: _ctx.i18n.t('robots.test'),
                  icon: "pi pi-chart-line",
                  iconPos: "right"
                }, null, 8, ["label"])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_router_link, { to: {
        name: 'RobotDetailPage',
        params: { robotId: 'notSet' },
      } }, {
      default: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.i18n.t('app.ajouter')
        }, null, 8, ["label"])
      ]),
      _: 1
    })
  ]))
}