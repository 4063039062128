
  import { defineComponent, ref, computed} from "vue";
  import { useToast } from "primevue/usetoast";
  import { useConfirm } from "primevue/useconfirm";
  import Card from "primevue/card";
  import Chart from "primevue/chart";
  import { useI18n } from "vue-i18n";
  import apiClient from "@/api-client";
  import router from "@/router";
  import { useUsersStore } from "@/store/users";
  import Dropdown from 'primevue/dropdown';
  import Compte from "@/models/comptes/Compte";
  import Asset from '@/models/comptes/Asset';
  import Histo from '@/models/comptes/Histo';
  import moment from "moment";
  
  export default defineComponent({
    components: {
      Card,
      Dropdown,
      Chart,
    },
    props: {
      compteId: {
        type: String,
      }
    },
    setup(props) {
      const i18n = useI18n();
      const toast = useToast();
      const confirm = useConfirm();
      const usersStore = useUsersStore();
      const compte = ref<Compte>(new Compte());
      const assets = ref<Asset[]>();
      const durees = ref<any[]>();
      const dureeSelect = ref<number>();
      const courbes = ref<any[]>();
      const courbe = ref<any>();
      const multiAxisOptions = ref<any>();
      const monoAxisOptions = ref<any>();
      const portfolio = ref<any>();

      durees.value = [{libelle: 'Une journée', nbJours: 1},
                      {libelle: 'Une semaine', nbJours: 7},
                      {libelle: 'Un mois', nbJours: 30},
                      {libelle: 'Un an', nbJours: 365},
                      {libelle: 'Tout', nbJours: 10000}];

      compte.value.id = props.compteId!;

      dureeSelect.value = 30;

      multiAxisOptions.value = {
                stacked: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            color: '#42A5F5'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        ticks: {
                            color: '#00bb7e'
                        },
                        grid: {
                            drawOnChartArea: false,
                            color: '#ebedef'
                        }
                    }
                }
            }

      monoAxisOptions.value = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#00bb7e'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }

      const onSelectDuree = () => {
        const dt = new Date();
        dt.setDate(dt.getDate() - dureeSelect.value!);
        
        courbes.value=[];
        apiClient.comptes.getAssets(compte.value, {since: moment(dt).format("YYYY-MM-DD")}).then((data: Asset[]) => {
          assets.value = data.map((asset) => new Asset(asset));
          for (const asset of assets.value) {
            apiClient.comptes.getHisto(compte.value, {since: moment(dt).format("YYYY-MM-DD"), asset: asset.asset}).then((data: Histo) => {
              if (data.asset.includes('USD')) {
                courbe.value = {
                  data: {
                    labels: data.dates, 
                    datasets: [
                      {
                        label: data.asset, 
                        data: data.qtes,
                        yAxisID: 'y',
                        borderColor: '#00bb7e',
                        pointRadius: 0,
                      }
                    ]
                  },
                  options: monoAxisOptions.value,
                }
              }
              else {
                courbe.value = {
                  data: {
                    labels: data.dates, 
                    datasets: [
                      {
                        label: data.asset, 
                        data: data.qtes,
                        yAxisID: 'y',
                        borderColor: '#42A5F5',
                        pointRadius: 0,
                      },
                      {
                        label: 'USD',
                        data: data.vals,
                        yAxisID: 'y1',
                        borderColor: '#00bb7e',
                        pointRadius: 0,
                      }
                    ]
                  },
                  options: multiAxisOptions.value,
                }
              }
              courbes.value!.push(courbe.value);
            });  
          }

          
          apiClient.comptes.getHisto(compte.value, {since: moment(dt).format("YYYY-MM-DD")}).then((data: Histo) => {
            portfolio.value = {
              data: {
                labels: data.dates,
                datasets: [
                  {
                    label: 'Portfolio USD',
                    data: data.vals,
                    yAxisID: 'y',
                    borderColor: '#00bb7e',
                    pointRadius: 0,
                  }
                ]
              },
              options: monoAxisOptions.value
            }
        });


        });
      }

      onSelectDuree();

      const appUser = computed(() => {
        return usersStore.state.appUser;
      });
  
 
      return {
        i18n,
        compte,
        appUser,
        can: usersStore.can,
        assets,
        durees,
        dureeSelect,
        onSelectDuree,
        courbes,
        portfolio,
      };
    },
  });
  