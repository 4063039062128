
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Robot from "@/models/robots/Robot";
import Market from '@/models/markets/Market';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import Feature from "@/models/features/Feature";
import RobotFeature from "@/models/robots/RobotFeature";
import ProgressBar from 'primevue/progressbar';
import moment from "moment";

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    Dropdown,
    Calendar,
    Textarea,
    ProgressBar,
  },
  props: {
    robotId: {
      type: String,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const toast = useToast();
    const confirm = useConfirm();
    const robot = ref<Robot>();
    const usersStore = useUsersStore();
    const markets = ref<Market[]>();
    const features = ref<Feature[]>();
    const feature = ref<Feature>();
    const robotFeatures = ref<RobotFeature[]>();

    const gener_models = ref<number[]>();
    const gener_model = ref<number>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    robot.value = new Robot();
    robot.value.id = props.robotId!;

    apiClient.features.getFeatures().then((data: Feature[]) => {
      features.value = data.map((feature) => new Feature(feature));
    });

    apiClient.markets.getMarkets().then((data: Market[]) => {
      markets.value = data.map((market) => Object.assign(new Market(), market));
    })

    if (props.robotId != "notSet") {
      apiClient.robots.getRobot(robot.value!).then((data: Robot) => {
        robot.value = new Robot(data);
        apiClient.robots.getRobotFeatures({robot: robot.value.id}).then((data: RobotFeature[]) => {
          robotFeatures.value = data.map((robotFeature) => new RobotFeature(robotFeature));
          if(robot.value!.gener_models != '') {
            gener_models.value = JSON.parse(robot.value!.gener_models).sort(function (a, b) {  return b - a;  });
            gener_model.value = gener_models.value![0];
          }
        });
      });

    } else {
      robot.value!.status = "Nouveau";
      robot.value!.valid_date_fin = moment(new Date()).subtract(1, 'year').toDate();
      robot.value!.training_date_fin = moment(robot.value!.valid_date_fin).subtract(1, 'year').toDate();
    }

    const saveRobot = () => {
      apiClient.robots.sendRobot(robot.value!).then((data: Robot) => {
        robot.value = Object.assign(new Robot(), data);

        for (const robotFeature of robotFeatures.value!) {
            apiClient.robots.sendRobotFeature(robotFeature);
        }

        router.back();
        toast.add({
          severity: "success",
          summary: i18n.t("robot.toast.create.summary"),
          detail: i18n.t("robot.toast.create.detail"),
          life: 5000,
        });
      });
    };

    const deleteRobot = () => {
      confirm.require({
        message: i18n.t("app.confirm.delete.message"),
        header: i18n.t("app.confirm.delete.header"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.robots.deleteRobot(robot.value!).then((data: Robot) => {
            robot.value = Object.assign(new Robot(), data);
            toast.add({
              severity: "success",
              summary: i18n.t("app.toast.delete.summary"),
              detail: i18n.t("app.toast.delete.detail"),
              life: 5000,
            });
            router.push({ path: "/robots" });
          });
        }
      });
    };

    const deleteRobotFeature = (robotFeature: RobotFeature) => {
      confirm.require({
        message: i18n.t("app.confirm.delete.message"),
        header: i18n.t("app.confirm.delete.header"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.robots.deleteRobotFeature(robotFeature).then((data: RobotFeature) => {
            toast.add({
              severity: "success",
              summary: i18n.t("app.toast.delete.summary"),
              detail: i18n.t("app.toast.delete.detail"),
              life: 5000,
            });
            apiClient.robots.getRobotFeatures({robot: robot.value!.id}).then((data: RobotFeature[]) => {
              robotFeatures.value = data.map((robotFeature) => new RobotFeature(robotFeature));
            });  
          });
        }
      });
    }

    const train = () => {
      apiClient.robots.train(robot.value!).then((data: Robot) => {
        robot.value = new Robot(data);
      });
    }

    const test = () => {
      router.push({ path: "/robots/" + robot.value!.id + "/test" });
    }

    const renderStats = () => {
      apiClient.robots.renderStats(robot.value!).then((data: any) => {
        const blob = new Blob([data], {type: 'image/svg+xml'}),
        url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    }

    const renderNet = () => {
      apiClient.robots.renderNet(robot.value!, gener_model.value!.toString()).then((data: any) => {
        const blob = new Blob([data], {type: 'image/svg+xml'}),
        url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    }

    const addFeature = () => {
      const robotFeature: RobotFeature = new RobotFeature();
      robotFeature.code = feature.value!.code;
      robotFeature.description = feature.value!.description;
      robotFeature.name = feature.value!.name;
      robotFeature.robot = robot.value!.id;
      robotFeature.id = "notSet";
      apiClient.robots.sendRobotFeature(robotFeature).then((data: RobotFeature) => {
        apiClient.robots.getRobotFeatures({robot: robot.value!.id}).then((data: RobotFeature[]) => {
          robotFeatures.value = data.map((robotFeature) => new RobotFeature(robotFeature));
        });  
      });
    }

    return {
      i18n,
      robot,
      saveRobot,
      deleteRobot,
      appUser,
      can: usersStore.can,
      markets,
      features,
      feature,
      train,
      test,
      renderStats,
      renderNet,
      addFeature,
      robotFeatures,
      deleteRobotFeature,
      gener_models,
      gener_model,
    };
  },
});
