
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import UtilisateurForm from '@/components/utilisateurs/UtilisateurForm.vue'

export default defineComponent({
  name: "UtilisateurDetailPage",
  components: {
    UtilisateurForm
  },
  setup() {
    const route = useRoute();
    const utilisateurId = route.params.utilisateurId =='null' ? null : route.params.utilisateurId;

    return {
      utilisateurId
    };
  },
});
