
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Compte from "@/models/comptes/Compte";
import Ordre from "@/models/comptes/Compte";
import Market from '@/models/markets/Market';
import Robot from "@/models/robots/Robot";
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Utilisateur from "@/models/utilisateurs/Utilisateur";
import Exchange from "@/models/exchanges/Exchange";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";

export default defineComponent({
  components: {
    InputText,
    Password,
    Button,
    Card,
    Dropdown,
    InputSwitch,
    DataTable,
    Column,
  },
  props: {
    compteId: {
      type: String,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const toast = useToast();
    const confirm = useConfirm();
    const compte = ref<Compte>(new Compte());
    const usersStore = useUsersStore();
    const markets = ref<Market[]>();
    const robots = ref<Robot[]>();
    const utilisateurs = ref<Utilisateur[]>();
    const exchanges = ref<Exchange[]>();
    
    const gener_models = ref<number[]>();
    const robot = ref<Robot>();
    
    const ordres = ref<Ordre[]>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    compte.value.id = props.compteId!;
    compte.value.user = appUser.value.id;

    apiClient.robots.getRobots({status: 'Entraîné'}).then((data: Robot[]) => {
      robots.value = data.map((robot) => new Robot(robot));
    });

    apiClient.utilisateurs.getUtilisateurs().then((data: Utilisateur[]) => {
      utilisateurs.value = data.map((utilisateur) => Object.assign(new Utilisateur(), utilisateur));
    })

    apiClient.exchanges.getExchanges().then((data: Exchange[]) => {
      exchanges.value = data.map((exchange) => Object.assign(new Exchange(), exchange));
    })

    if (props.compteId != "notSet") {
      apiClient.comptes.getCompte(compte.value!).then((data: Compte) => {
        compte.value = new Compte(data);
        loadMarkets();
        selectRobot(false);
        apiClient.comptes.getOrdres(compte.value).then((data: Ordre[]) => {
          ordres.value = data.map((ordre) => Object.assign(new Ordre(), ordre));
        });
      });
    }

    const isValid = () => {
      let isValid = true;

      if(
        !compte.value.name
        || !compte.value.exchange
        || !compte.value.user
        || !compte.value.api_key
        || !compte.value.api_secret
        ) {
        isValid = false;
        toast.add({
          severity: "warn",
          summary: i18n.t("comptes.toast.create_error.summary"),
          detail: i18n.t("comptes.toast.create_error.detail"),
          life: 5000,
        });
      }

      return isValid;
    }

    const saveCompte = () => {
      if(!isValid()) {
        return;
      }
      apiClient.comptes.sendCompte(compte.value!).then((data: Compte) => {
        compte.value = Object.assign(new Compte(), data);
        router.back();
        toast.add({
          severity: "success",
          summary: i18n.t("comptes.toast.create.summary"),
          detail: i18n.t("comptes.toast.create.detail"),
          life: 5000,
        });
      });
    };

    const deleteCompte = () => {
      confirm.require({
        message: i18n.t("app.confirm.delete.message"),
        header: i18n.t("app.confirm.delete.header"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.comptes.deleteCompte(compte.value!).then((data: Compte) => {
            compte.value = Object.assign(new Compte(), data);
            toast.add({
              severity: "success",
              summary: i18n.t("app.toast.delete.summary"),
              detail: i18n.t("app.toast.delete.detail"),
              life: 5000,
            });
            router.push({ path: "/comptes" });
          });
        }
      });
    };

    const exchangeChange = () => {
      loadMarkets();
    }

    const loadMarkets = () => {
      apiClient.markets.getMarkets({exchange: compte.value.exchange}).then((data: Market[]) => {
        markets.value = data.map((market) => Object.assign(new Market(), market));
      });
    } 

    const selectRobot = (defaultValue: boolean) => {
      console.log(compte.value.robot);
      gener_models.value = undefined;
      robot.value = new Robot();
      robot.value!.id = compte.value.robot;
      apiClient.robots.getRobot(robot.value!).then((data: Robot) => {
        const robot = new Robot(data);
        gener_models.value = JSON.parse(robot.gener_prod).sort(function (a, b) {  return b - a;  });
        if (defaultValue) compte.value.generation = gener_models.value![0];
      });
    }

    return {
      i18n,
      compte,
      saveCompte,
      deleteCompte,
      appUser,
      can: usersStore.can,
      markets,
      utilisateurs,
      exchanges,
      robots,
      exchangeChange,
      gener_models,
      selectRobot,
      ordres,
      moment,
    };
  },
});
