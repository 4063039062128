export default class Market {
  id?: string;
  name?: string;
  exchange?: any;
  date_debut?: Date;
  date_fin?: Date;

  constructor() {
    this.id = undefined;
    this.name = undefined;
    this.exchange = undefined;
    this.date_debut = new Date();
    this.date_fin = undefined;
  }

  get longName(): string {
    return this.name + " @" + this.exchange.name;
  }
}