import MenuCategory from "./MenuCategory"

export default class Menu {
  id: string;
  name?: string;
  categories?: MenuCategory[];

  constructor() {
    this.id = 'notSet';
    this.name = undefined;
    this.categories = [];
  }
}