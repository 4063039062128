// group our constants in a namespace
export namespace UsersMutationType {
    export const loadAppUser = 'loadAppUser'
    export const loadingAppUser = 'loadingAppUser'
    export const loadedAppUser = 'loadedAppUser'
    export const loadedFromLocalstorageAppUser = 'loadedFromLocalstorageAppUser'
    export const loadFromLocalstorageAppUser = 'loadFromLocalstorageAppUser'
    export const logedoutAppUser = 'logedoutAppUser'
    export const logoutAppUser = 'logoutAppUser'
    export const simulateUser = 'simulateUser'
    export const simulatedUser = 'simulatedUser'
    
    // as you add new mutations to the Users store module, keep adding their names here as above
}