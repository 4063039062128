import Choice from '@/models/choices/Choice'
import { HttpClient } from '@/http-client'

class ChoicesApiClient {
    urls = {
        getChoices: process.env.VUE_APP_API_URL + '/api/choices'
    }

    getChoices(apiFilters?: any): Promise<Choice[]> {
        const getParameters = {
            url: this.urls.getChoices,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Choice[]>(getParameters)
    }
}

export default new ChoicesApiClient