import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "features-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, { value: _ctx.features }, {
      default: _withCtx(() => [
        (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              field: "id",
              header: _ctx.i18n.t('features.id')
            }, null, 8, ["header"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.i18n.t('features.name')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "description",
          header: _ctx.i18n.t('features.description')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          header: "",
          style: {"width":"50px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_router_link, {
              to: {
              name: 'FeatureDetailPage',
              params: { featureId: slotProps.data.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  class: "menu-icon",
                  icon: "arrow-circle-right"
                })
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_router_link, { to: {
        name: 'FeatureDetailPage',
        params: { featureId: 'notSet' },
      } }, {
      default: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.i18n.t('app.ajouter')
        }, null, 8, ["label"])
      ]),
      _: 1
    })
  ]))
}