
import { defineComponent, ref, onBeforeMount, computed } from "vue";
import Utilisateur from "@/models/utilisateurs/Utilisateur";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import AppDataTable from "@/components/app/AppDataTable.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  name: "UtilisateursList",
  components: {
    AppDataTable,
    Button,
  },
  props: {
    uuid: {
      type: String,
    },
    appClient: {
      type: String,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const utilisateurs_lead = ref<Utilisateur[]>();
    const utilisateurs_collab = ref<Utilisateur[]>();
    const loading = ref(true);
    const usersStore = useUsersStore();

    const dataTableOptions = {
      addRoute: undefined,
      rowRoute: {
        name: "UtilisateurDetailPage",
        params: "utilisateurId",
      }
    };

    const columns = ref();

    const colFilters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      is_active: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nom: { value: null, matchMode: FilterMatchMode.CONTAINS },
      prenom: { value: null, matchMode: FilterMatchMode.CONTAINS },
      email: { value: null, matchMode: FilterMatchMode.CONTAINS },
      allGroupsNames: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const apiFilterLead = ref<any>()
    const apiFilterCollab = ref<any>()
    if(props.appClient){
      apiFilterLead.value = {"type": "Lead", 'app_client' : props.appClient}
      apiFilterCollab.value = {"type": "Collaborateur", 'app_client' : props.appClient}
    }else{
      apiFilterLead.value = {"type": "Lead"}
      apiFilterCollab.value = {"type": "Collaborateur"}
    }
    loading.value = true;
    apiClient.utilisateurs.getUtilisateurs(apiFilterLead.value).then((data: Utilisateur[]) => {
      utilisateurs_lead.value = data.map((utilisateur) => Object.assign(new Utilisateur(), utilisateur));
      loading.value = false;
    });

    loading.value = true;
    apiClient.utilisateurs.getUtilisateurs(apiFilterCollab.value).then((data: Utilisateur[]) => {
      utilisateurs_collab.value = data.map((utilisateur) => Object.assign(new Utilisateur(), utilisateur));
      loading.value = false;
    });

    onBeforeMount(() => {
      columns.value = [
        {
          field: "is_active",
          header: i18n.t("user.active"),
          icon: "pi pi-check-circle",
          sortable: true,
          filter: false,
          reorder: false,
          visible: true,
          obligatoire: false,
          type: "boolean",
        },
        {
          field: "nom",
          header: i18n.t("collaborateur.nom"),
          icon: "pi pi-user",
          sortable: true,
          filter: true,
          reorder: false,
          visible: true,
          obligatoire: true,
          type: "text",
        },
        {
          field: "prenom",
          header: i18n.t("collaborateur.prenom"),
          icon: "pi pi-user",
          sortable: true,
          filter: true,
          reorder: false,
          visible: true,
          obligatoire: true,
          type: "text",
        },
        {
          field: "email",
          header: i18n.t("user.email"),
          icon: "pi pi-envelope",
          sortable: true,
          filter: true,
          reorder: false,
          visible: true,
          obligatoire: true,
          type: "text",
        },
        {
          field: "allGroupsNames",
          header: i18n.t("user.groups"),
          icon: "pi pi-key",
          sortable: true,
          filter: true,
          reorder: false,
          visible: true,
          obligatoire: false,
          type: "text",
        },
        {
          field: "statutTexte",
          header: i18n.t("user.statut"),
          icon: "pi pi-info-circle",
          sortable: true,
          filter: false,
          reorder: false,
          visible: true,
          obligatoire: false,
          type: "text",
        },
      ];
    });

    return {
      i18n,
      utilisateurs_lead,
      utilisateurs_collab,
      loading,
      columns,
      colFilters,
      can: usersStore.can,
      dataTableOptions,
    };
  },
});
