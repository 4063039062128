
import { defineComponent, ref } from "vue";
import Compte from "@/models/comptes/Compte";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import ComptesList from "@/components/comptes/ComptesList.vue";

export default defineComponent({
  name: "ComptesPage",
  components: {
    Card,
    ComptesList,
  },
  setup() {
    const i18n = useI18n();
    const Comptes = ref<Compte[]>();

    return {
      Comptes,
      i18n,
    };
  },
});
