import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "feature-form" }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "p-field p-grid"
}
const _hoisted_4 = {
  for: "id",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_5 = { class: "p-col-12 p-md-10" }
const _hoisted_6 = { class: "p-field p-grid" }
const _hoisted_7 = {
  for: "name",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_8 = { class: "p-col-12 p-md-10" }
const _hoisted_9 = { class: "p-field p-grid" }
const _hoisted_10 = {
  for: "code",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_11 = { class: "p-col-12 p-md-10" }
const _hoisted_12 = { class: "p-field p-grid" }
const _hoisted_13 = {
  for: "description",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_14 = { class: "p-col-12 p-md-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Textarea = _resolveComponent("Textarea")
  const _component_Button = _resolveComponent("Button")
  const _component_router_link = _resolveComponent("router-link")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode("label", _hoisted_4, [
                  _createVNode(_component_font_awesome_icon, { icon: "key" })
                ]),
                _createVNode("div", _hoisted_5, [
                  _createVNode(_component_InputText, {
                    disabled: "",
                    modelValue: _ctx.feature.id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.feature.id = $event)),
                    id: "id",
                    type: "text"
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_6, [
            _createVNode("label", _hoisted_7, _toDisplayString(_ctx.i18n.t("features.name")), 1),
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.feature.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.feature.name = $event)),
                id: "name",
                type: "text",
                spellcheck: "false",
                disabled: !_ctx.can('features.add_feature') && !_ctx.can('features.change_feature')
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_9, [
            _createVNode("label", _hoisted_10, _toDisplayString(_ctx.i18n.t("features.code")), 1),
            _createVNode("div", _hoisted_11, [
              _createVNode(_component_Textarea, {
                modelValue: _ctx.feature.code,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.feature.code = $event)),
                autoResize: true,
                rows: "3",
                cols: "30",
                id: "code",
                spellcheck: "false",
                disabled: !_ctx.can('features.add_feature') && !_ctx.can('features.change_feature'),
                style: {"font-family":"monospace","color":"#007bff"}
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_12, [
            _createVNode("label", _hoisted_13, _toDisplayString(_ctx.i18n.t("features.description")), 1),
            _createVNode("div", _hoisted_14, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.feature.description,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.feature.description = $event)),
                id: "description",
                type: "text",
                spellcheck: "false",
                disabled: !_ctx.can('features.add_feature') && !_ctx.can('features.change_feature')
              }, null, 8, ["modelValue", "disabled"])
            ])
          ])
        ])
      ]),
      footer: _withCtx(() => [
        (_ctx.can('features.add_feature') || _ctx.can('features.change_feature'))
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _ctx.saveFeature,
              label: _ctx.i18n.t('app.enregistrer'),
              class: "p-button-success",
              icon: "pi pi-save",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.can('features.delete_feature') && _ctx.featureId != 'notSet')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              onClick: _ctx.deleteFeature,
              label: _ctx.i18n.t('app.supprimer'),
              class: "p-button-danger",
              icon: "pi pi-trash",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.can('users.view_audit'))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              to: {
            name: 'AuditsPage',
            params: { table: 'features_feature', id: _ctx.feature.id },
          }
            }, {
              default: _withCtx(() => [
                (_ctx.featureId != 'notSet')
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: _ctx.i18n.t('app.audit'),
                      icon: "pi pi-file-excel",
                      iconPos: "right",
                      class: "p-button-help"
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}