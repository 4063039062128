import Audit from '@/models/audits/Audit'
import { HttpClient } from '@/http-client'

class AuditsApiClient {
    urls = {
        getAudits: process.env.VUE_APP_API_URL + '/api/audits',
        getAudit: process.env.VUE_APP_API_URL + '/api/audits/$(auditId)',
    }

    getAudits(filters: any): Promise<Audit[]> {
        const getParameters = {
            url: this.urls.getAudits,
            requiresToken: false,
            filters: filters
        }
        return HttpClient.get<Audit[]>(getParameters)
    }
    getAudit(audit: Audit): Promise<Audit> {
        const getParameters = {
            url: this.urls.getAudit.replace('$(auditId)', audit.id),
            requiresToken: false
        }
        return HttpClient.get<Audit>(getParameters)
    }
}

export default new AuditsApiClient
