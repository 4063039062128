import Exchange from '@/models/exchanges/Exchange'
import { HttpClient } from '@/http-client'

class ExchangesApiClient {
    urls = {
        getExchanges: process.env.VUE_APP_API_URL + '/api/exchanges',
        getExchange: process.env.VUE_APP_API_URL + '/api/exchanges/$(exchangeId)',
        postExchange: process.env.VUE_APP_API_URL + '/api/exchanges',
        putExchange: process.env.VUE_APP_API_URL + '/api/exchanges/$(exchangeId)',
        deleteExchange: process.env.VUE_APP_API_URL + '/api/exchanges/$(exchangeId)'
    }

    getExchanges(apiFilters?: any): Promise<Exchange[]> {
        const getParameters = {
            url: this.urls.getExchanges,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Exchange[]>(getParameters)
    }

    getExchange(exchange: Exchange): Promise<Exchange> {
        const getParameters = {
            url: this.urls.getExchange.replace('$(exchangeId)', exchange.id!)
        }
        return HttpClient.get<Exchange>(getParameters)
    }

    sendExchange(_exchange: Exchange): Promise<Exchange> {
        const exchange = Object.assign({}, _exchange);
        if (exchange.id != 'notSet') {
            const getParameters = {
                url: this.urls.putExchange.replace('$(exchangeId)', exchange.id!),
                requiresToken: false,
                payload: exchange,
            }
            return HttpClient.put<Exchange>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postExchange,
                requiresToken: false,
                payload: exchange,
            }
            return HttpClient.post<Exchange>(getParameters)
        }
    }

    deleteExchange(exchange: Exchange): Promise<Exchange> {
        const getParameters = {
            url: this.urls.deleteExchange.replace('$(exchangeId)', exchange.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Exchange>(getParameters)
    }
}

export default new ExchangesApiClient;