
import { defineComponent, ref, PropType, onBeforeMount, computed } from "vue";
import { useI18n } from "vue-i18n";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Tooltip from "primevue/tooltip";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import AppDataTableColumn from "@/models/app/AppDataTableColumn";
import { useUsersStore } from "@/store/users";
import router from "@/router";
import moment from "moment";

export default defineComponent({
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    MultiSelect,
    Calendar,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    loading: {
      type: Boolean,
    },
    columns: {
      type: Array as PropType<AppDataTableColumn[]>,
    },
    data: {
      type: Array,
    },
    colFilters: {
      type: Object,
    },
    uuid: {
      type: String,
    },
    dataTableOptions: {
      type: Object,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const dt = ref();
    const usersStore = useUsersStore();
    const globalFilterFields = ref<string[]>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const mode = ref<string>("light")

    const exportCSV = () => {
      dt.value.exportCSV();
    };

    const selectedColumns = ref();

    const columnsObligatoires = ref(
      props.columns!.filter((col) => col.obligatoire == true)
    );

    const onToggle = (val: any) => {
      selectedColumns.value = props.columns?.filter((col) => val.includes(col));
      const localStorageSelectedColumns: any = [];
      selectedColumns.value.forEach((col: any) => {
        localStorageSelectedColumns.push(col.field);
      });
      localStorage.setItem(
        "appDataTable-" + props.uuid + "-selectedColumns",
        JSON.stringify(localStorageSelectedColumns)
      );
    };

    const filters = ref(props.colFilters);

    const onRowClick = (event: any) => {
      router.push({
        name: props.dataTableOptions!.rowRoute.name,
        params: {
          [props.dataTableOptions!.rowRoute.params]: event.data.id,
        },
      });
    };

    onBeforeMount(() => {
      if(appUser.value.profil?.dark_mode){
        mode.value = "dark"
      }

      selectedColumns.value = props.columns?.filter(
        (col) => col.visible && col.obligatoire == false
      );

      if (
        localStorage.getItem("appDataTable-" + props.uuid + "-selectedColumns")
      ) {
        const localStorageSelectedColumns = JSON.parse(
          localStorage.getItem(
            "appDataTable-" + props.uuid + "-selectedColumns"
          )!
        );

        selectedColumns.value = selectedColumns.value.filter((col: any) =>
          localStorageSelectedColumns.includes(col.field)
        );
      }

      globalFilterFields.value = [];
      props.columns?.forEach((col) => {
        globalFilterFields.value!.push(col.field);
      });
    });

    return {
      i18n,
      moment,
      mode,
      dt,
      exportCSV,
      selectedColumns,
      onToggle,
      filters,
      appUser,
      can: usersStore.can,
      globalFilterFields,
      columnsObligatoires,
      onRowClick,
    };
  },
});
