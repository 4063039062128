import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login-body" }
const _hoisted_2 = { class: "body-container" }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-col-12 p-lg-6 left-side" }
const _hoisted_5 = { class: "p-col-12 p-lg-6 right-side" }
const _hoisted_6 = { class: "login-wrapper" }
const _hoisted_7 = { class: "login-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createVNode("img", {
                src: 'assets/layout/images/crypthune-logo.png',
                alt: "crypthune"
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode("h1", null, _toDisplayString(_ctx.i18n.t("forbidden.title")), 1),
          _createVNode("p", null, _toDisplayString(_ctx.i18n.t("forbidden.subtitle")), 1),
          _createVNode("p", null, _toDisplayString(_ctx.i18n.t("forbidden.message")), 1)
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createVNode("div", _hoisted_7, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    label: _ctx.i18n.t('forbidden.returnHome'),
                    icon: "pi pi-check",
                    iconPos: "right"
                  }, null, 8, ["label"])
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ])
  ]))
}