export default class Compte {
  id: string;
  name: string;
  exchange: any;
  user: any;
  robot: any;
  market: any;
  api_key: string;
  api_secret: string;
  generation: number;
  trading_actif: boolean;
  pct_achat_base: number;
  pct_vente_asset: number;
  can_buy: boolean;
  can_sell: boolean;

  constructor(x?: any) {
    this.id = x && x.id ? x.id : 'notSet';
    this.name = x && x.name ? x.name : undefined;
    this.exchange = x && x.exchange ? x.exchange : undefined;
    this.user = x && x.user ? x.user : undefined;
    this.robot = x && x.robot ? x.robot : undefined;
    this.market = x && x.market ? x.market : undefined;
    this.api_key = x && x.api_key ? x.api_key : undefined;
    this.api_secret = x && x.api_secret ? x.api_secret : undefined;
    this.generation = x && x.generation ? x.generation : undefined;
    this.trading_actif = x && x.trading_actif ? x.trading_actif : undefined;
    this.pct_achat_base = x && x.pct_achat_base ? x.pct_achat_base : undefined;
    this.pct_vente_asset = x && x.pct_vente_asset ? x.pct_vente_asset : undefined;
    this.can_buy = x && x.can_buy ? x.can_buy : undefined;
    this.can_sell = x && x.can_sell ? x.can_sell : undefined;
  }
}