
import { computed, defineComponent, ref } from "vue";
import Compte from '@/models/comptes/Compte';
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import Button from "primevue/button";
import { useUsersStore } from "@/store/users";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";
import InputSwitch from 'primevue/inputswitch';

export default defineComponent({
  name: "ComptesList",
  components: {
    Button,
    DataTable,
    Column,
    InputSwitch,
  },
  props: {
    uuid: {
      type: String,
    },
  },
  setup() {
    const i18n = useI18n();
    const comptes = ref<Compte[]>();
    const loading = ref(true);
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });
   
    loading.value = true;
    apiClient.comptes.getComptes().then((data: Compte[]) => {
      comptes.value = data.map((compte) => new Compte(compte));
      loading.value = false;
    });

    return {
      i18n,
      comptes,
      loading,
      can: usersStore.can,
      appUser,
      moment,
    };
  },
});
