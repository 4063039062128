import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "utilisateurs-list" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col" }
const _hoisted_4 = { class: "p-col table-title" }
const _hoisted_5 = { class: "p-col" }
const _hoisted_6 = { class: "p-col table-title" }
const _hoisted_7 = { class: "p-grid filter-histo" }
const _hoisted_8 = { class: "p-col p-text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDataTable = _resolveComponent("AppDataTable")
  const _component_Button = _resolveComponent("Button")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.i18n.t('navigation.crypthune.leads')), 1),
        _createVNode(_component_AppDataTable, {
          data: _ctx.utilisateurs_lead,
          colFilters: _ctx.colFilters,
          columns: _ctx.columns,
          loading: _ctx.loading,
          uuid: _ctx.uuid,
          dataTableOptions: _ctx.dataTableOptions
        }, null, 8, ["data", "colFilters", "columns", "loading", "uuid", "dataTableOptions"])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, _toDisplayString(_ctx.i18n.t('navigation.crypthune.collaborateurs')), 1),
        _createVNode(_component_AppDataTable, {
          data: _ctx.utilisateurs_collab,
          colFilters: _ctx.colFilters,
          columns: _ctx.columns,
          loading: _ctx.loading,
          uuid: _ctx.uuid,
          dataTableOptions: _ctx.dataTableOptions
        }, null, 8, ["data", "colFilters", "columns", "loading", "uuid", "dataTableOptions"])
      ])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode("div", _hoisted_8, [
        (_ctx.can('users.view_audit'))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {
            name: 'AuditsPage',
            params: { table: 'users_user', id: 'all' },
          }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: _ctx.i18n.t('app.audit'),
                  icon: "pi pi-file-excel",
                  iconPos: "right",
                  class: "p-button-help p-button-sm"
                }, null, 8, ["label"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}