export default class Exchange {
  id?: string;
  name?: string;
  fees?: number;

  constructor() {
    this.id = undefined;
    this.name = undefined;
    this.fees = undefined;
  }
}