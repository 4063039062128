import { rootStore, dispatchModuleAction } from '../root'
import { StoreModuleNames, UsersStateInterface } from '@/models/store'
import { ref } from "vue";
/**
* @name UsersStore
* @description
* The users store wrapper that returns the usersState and exposes a generic action<T> method
*/
const usersStore = {
    get state(): UsersStateInterface {
        return rootStore.state.usersState
    },
    can(permission: string): boolean {
        if(!rootStore.state.usersState.appUser.permissions)
            return false;
        return  rootStore.state.usersState.appUser.is_super_user == true || rootStore.state.usersState.appUser.permissions.indexOf(permission) >= 0
    },
    action<T>(actionName: string, params?: T): void {
        dispatchModuleAction(StoreModuleNames.usersState, actionName, params)
    }
}
// export our wrapper using the composition API convention (i.e. useXYZ)
export const useUsersStore = () => {
    return usersStore
}