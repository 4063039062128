
import { defineComponent } from "vue";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import AppInfo from "@/components/app/AppInfo.vue";
import AppVersion from "@/components/app/AppVersion.vue";
import { useUsersStore } from "@/store/users";
import Button from "primevue/button";

export default defineComponent({
  name: "DashboardLeftMenu",
  components: {
    AppInfo,
    AppVersion,
    Card,
  },
  setup() {
    const i18n = useI18n();
    const usersStore = useUsersStore();

    return {
      i18n,
      can: usersStore.can,
    };
  },
});
