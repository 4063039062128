
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Robot from "@/models/robots/Robot";
import Market from '@/models/markets/Market';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import Feature from "@/models/features/Feature";
import moment from "moment";

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    Dropdown,
    Calendar,
    Textarea,
  },
  props: {
    robotId: {
      type: String,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const toast = useToast();
    const confirm = useConfirm();
    const robot = ref<Robot>();
    const usersStore = useUsersStore();
    const markets = ref<Market[]>();
    const features = ref<Feature[]>();
    const result = ref<string>();

    const gener_models = ref<number[]>();
    const gener_model = ref<number>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    robot.value = new Robot();
    robot.value.id = props.robotId!;

    apiClient.features.getFeatures().then((data: Feature[]) => {
      features.value = data.map((feature) => new Feature(feature));
    });

    apiClient.markets.getMarkets().then((data: Market[]) => {
      markets.value = data.map((market) => Object.assign(new Market(), market));
    })

    if (props.robotId != "notSet") {
      apiClient.robots.getRobot(robot.value!).then((data: Robot) => {
        robot.value = new Robot(data);
        if(robot.value!.gener_models != '') {
          gener_models.value = JSON.parse(robot.value!.gener_models).sort(function (a, b) {  return b - a;  });
          gener_model.value = gener_models.value![0];
        }
      });
    } else {
      robot.value!.status = "Nouveau";
    }

    const test = () => {
      apiClient.robots.test(robot.value!, robot.value!.training_market, moment(robot.value!.training_date_debut).format('YYYY-MM-DD') , moment(robot.value!.training_date_fin).format('YYYY-MM-DD'), gener_model.value!.toString() ).then((data: string) => {
        
        const stats = data['stats'];
        const html = data['html'];
        console.log(stats);
        result.value = stats;
        const blob = new Blob([html], {type: 'text/html'}),
        url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    }

    const dates_to_today = () => {
      robot.value!.training_date_debut = robot.value!.training_date_fin;
      robot.value!.training_date_fin = new Date();
    }

    return {
      i18n,
      robot,
      appUser,
      can: usersStore.can,
      markets,
      features,
      test,
      result,
      dates_to_today,
      gener_models,
      gener_model,
    };
  },
});
