import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "utilisateurs-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UtilisateursList = _resolveComponent("UtilisateursList")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_UtilisateursList, { uuid: "8e905360-8692-4f40-9c6e-864cc1c329dc" })
      ]),
      _: 1
    })
  ]))
}