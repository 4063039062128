import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "group-form" }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "p-field p-grid"
}
const _hoisted_4 = {
  for: "id",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_5 = { class: "p-col-12 p-md-10" }
const _hoisted_6 = { class: "p-field p-grid" }
const _hoisted_7 = {
  for: "name",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_8 = { class: "p-col-12 p-md-10" }
const _hoisted_9 = { class: "p-field p-grid" }
const _hoisted_10 = {
  for: "permissions",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_11 = { class: "p-col-12 p-md-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_PermissionsMatrix = _resolveComponent("PermissionsMatrix")
  const _component_Button = _resolveComponent("Button")
  const _component_router_link = _resolveComponent("router-link")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_ConfirmDialog),
        _createVNode("div", _hoisted_2, [
          (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode("label", _hoisted_4, [
                  _createVNode(_component_font_awesome_icon, { icon: "key" })
                ]),
                _createVNode("div", _hoisted_5, [
                  _createVNode(_component_InputText, {
                    disabled: "",
                    modelValue: _ctx.group.id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.group.id = $event)),
                    id: "id",
                    type: "text"
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_6, [
            _createVNode("label", _hoisted_7, _toDisplayString(_ctx.i18n.t("group.name")), 1),
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.group.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.group.name = $event)),
                id: "name",
                type: "text",
                disabled: !_ctx.can('auth.add_group') && !_ctx.can('auth.change_group')
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_9, [
            _createVNode("label", _hoisted_10, _toDisplayString(_ctx.i18n.t("group.permissions")), 1),
            _createVNode("div", _hoisted_11, [
              (_ctx.groupId != 'notSet')
                ? (_openBlock(), _createBlock(_component_PermissionsMatrix, {
                    key: 0,
                    group: _ctx.group
                  }, null, 8, ["group"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      footer: _withCtx(() => [
        (_ctx.can('auth.add_group') || _ctx.can('auth.change_group'))
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _ctx.saveGroup,
              label: _ctx.i18n.t('app.enregistrer'),
              class: "p-button-success",
              icon: "pi pi-save",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.can('auth.delete_group') && _ctx.groupId != 'notSet')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              onClick: _ctx.deleteGroup,
              label: _ctx.i18n.t('app.supprimer'),
              class: "p-button-danger",
              icon: "pi pi-trash",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.can('users.view_audit'))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              to: {
            name: 'AuditsPage',
            params: { table: 'auth_group', id: _ctx.group.id },
          }
            }, {
              default: _withCtx(() => [
                (_ctx.groupId != 'notSet')
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: _ctx.i18n.t('app.audit'),
                      icon: "pi pi-file-excel",
                      iconPos: "right",
                      class: "p-button-help"
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}