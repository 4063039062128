
import { defineComponent, ref } from "vue";
import Robot from "@/models/robots/Robot";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import RobotsList from "@/components/robots/RobotsList.vue";

export default defineComponent({
  name: "RobotsPage",
  components: {
    Card,
    RobotsList,
  },
  setup() {
    const i18n = useI18n();
    const Robots = ref<Robot[]>();

    return {
      Robots,
      i18n,
    };
  },
});
