import Robot from '@/models/robots/Robot'
import RobotFeature from '@/models/robots/RobotFeature'
import { HttpClient } from '@/http-client'

class RobotsApiClient {
    urls = {
        getRobots: process.env.VUE_APP_API_URL + '/api/robots',
        getRobot: process.env.VUE_APP_API_URL + '/api/robots/$(robotId)',
        postRobot: process.env.VUE_APP_API_URL + '/api/robots',
        putRobot: process.env.VUE_APP_API_URL + '/api/robots/$(robotId)',
        deleteRobot: process.env.VUE_APP_API_URL + '/api/robots/$(robotId)',

        trainRobot: process.env.VUE_APP_API_URL + '/api/robots/$(robotId)/train',
        testRobot: process.env.VUE_APP_API_URL + '/api/robots/$(robotId)/test?market=$(marketId)&date_deb=$(dateDeb)&date_fin=$(dateFin)&gener=$(gener)',
        renderStatsRobot: process.env.VUE_APP_API_URL + '/api/robots/$(robotId)/render_stats',
        renderNetRobot: process.env.VUE_APP_API_URL + '/api/robots/$(robotId)/render_net?gener=$(gener)',

        getRobotFeatures: process.env.VUE_APP_API_URL + '/api/robotFeatures',
        postRobotFeature: process.env.VUE_APP_API_URL + '/api/robotFeatures',
        putRobotFeature: process.env.VUE_APP_API_URL + '/api/robotFeatures/$(robotFeatureId)',
        deleteRobotFeature: process.env.VUE_APP_API_URL + '/api/robotFeatures/$(robotFeatureId)',
    }

    getRobots(apiFilters?: any): Promise<Robot[]> {
        const getParameters = {
            url: this.urls.getRobots,
            filters: apiFilters,
        }
        return HttpClient.get<Robot[]>(getParameters)
    }

    getRobot(robot: Robot): Promise<Robot> {
        const getParameters = {
            url: this.urls.getRobot.replace('$(robotId)', robot.id!)
        }
        return HttpClient.get<Robot>(getParameters)
    }

    sendRobot(_robot: Robot): Promise<Robot> {
        const robot = Object.assign({}, _robot);
        if (robot.id != 'notSet') {
            const getParameters = {
                url: this.urls.putRobot.replace('$(robotId)', robot.id!),
                payload: robot,
            }
            return HttpClient.put<Robot>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postRobot,
                payload: robot,
            }
            return HttpClient.post<Robot>(getParameters)
        }
    }

    deleteRobot(robot: Robot): Promise<Robot> {
        const getParameters = {
            url: this.urls.deleteRobot.replace('$(robotId)', robot.id!),
        }
        return HttpClient.delete<Robot>(getParameters)
    }

    train(robot: Robot): Promise<Robot> {
        const getParameters = {
            url: this.urls.trainRobot.replace('$(robotId)', robot.id!)
        }
        return HttpClient.put<Robot>(getParameters)
    }

    test(robot: Robot, marketId: string, date_deb: string, date_fin: string, gener: string): Promise<string> {
        const getParameters = {
            url: this.urls.testRobot
                .replace('$(robotId)', robot.id!)
                .replace('$(marketId)', marketId!)
                .replace('$(dateDeb)', date_deb!)
                .replace('$(dateFin)', date_fin!)
                .replace('$(gener)', gener)
        }
        return HttpClient.get<string>(getParameters)
    }

    renderStats(robot: Robot): Promise<any> {
        const getParameters = {
            url: this.urls.renderStatsRobot
                .replace('$(robotId)', robot.id!)
        }
        return HttpClient.get<any>(getParameters)
    }

    renderNet(robot: Robot, gener: string): Promise<any> {
        const getParameters = {
            url: this.urls.renderNetRobot
                .replace('$(robotId)', robot.id!)
                .replace('$(gener)', gener)
        }
        return HttpClient.get<any>(getParameters)
    }


    getRobotFeatures(apiFilters?: any): Promise<RobotFeature[]> {
        const getParameters = {
            url: this.urls.getRobotFeatures,
            filters: apiFilters,
        }
        return HttpClient.get<RobotFeature[]>(getParameters)
    }

    sendRobotFeature(_robotfeature: RobotFeature): Promise<RobotFeature> {
        const robotfeature = Object.assign({}, _robotfeature);
        if (robotfeature.id != 'notSet') {
            const getParameters = {
                url: this.urls.putRobotFeature.replace('$(robotFeatureId)', robotfeature.id!),
                payload: robotfeature,
            }
            return HttpClient.put<RobotFeature>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postRobotFeature,
                payload: robotfeature,
            }
            return HttpClient.post<RobotFeature>(getParameters)
        }
    }

    deleteRobotFeature(robotfeature: RobotFeature): Promise<RobotFeature> {
        const getParameters = {
            url: this.urls.deleteRobotFeature.replace('$(robotFeatureId)', robotfeature.id!),
        }
        return HttpClient.delete<RobotFeature>(getParameters)
    }

}

export default new RobotsApiClient;