export default class Feature {
  id: string;
  name: string;
  code: string;
  description: string;
  actif: boolean;

  constructor(x?: any) {
    this.id = x && x.id ? x.id : 'notSet';
    this.name = x && x.name ? x.name : undefined;
    this.code = x && x.code ? x.code : undefined;
    this.description = x && x.description ? x.description : undefined;
    this.actif = x && x.actif ? x.actif : undefined;
  }
}