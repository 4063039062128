
import { defineComponent } from "vue";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "HomePage",
  components: {
  },
  setup() {
    const route = useRoute();
    return {
    };
  },
});
