
  import { defineComponent } from "vue";
  import { useRoute } from 'vue-router'
  import CompteHisto from '@/components/comptes/CompteHisto.vue'
  
  export default defineComponent({
    name: "CompteHistoPage",
    components: {
      CompteHisto
    },
    setup() {
      const route = useRoute();
      const compteId = route.params.compteId =='null' ? null : route.params.compteId;
  
      return {
        compteId
      };
    },
  });
  