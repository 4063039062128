
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Market from "@/models/markets/Market";
import Exchange from "@/models/exchanges/Exchange";
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    Dropdown
  },
  props: {
    marketId: {
      type: String,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const toast = useToast();
    const confirm = useConfirm();
    const market = ref<Market>();
    const usersStore = useUsersStore();
    const exchanges = ref<Exchange[]>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    market.value = new Market();
    market.value.id = props.marketId!;

    apiClient.exchanges.getExchanges().then((data: Exchange[]) => {
      exchanges.value = data.map((exchange) => Object.assign(new Exchange(), exchange));

      if (props.marketId != "notSet") {
        apiClient.markets.getMarket(market.value!).then((data: Market) => {
          market.value = Object.assign(new Market(), data);
        });
      }
    });

    const saveMarket = () => {
      apiClient.markets.sendMarket(market.value!).then((data: Market) => {
        market.value = Object.assign(new Market(), data);
        router.back();
        toast.add({
          severity: "success",
          summary: i18n.t("market.toast.create.summary"),
          detail: i18n.t("market.toast.create.detail"),
          life: 5000,
        });
      });
    };

    const deleteMarket = () => {
      confirm.require({
        message: i18n.t("market.confirm.delete.message"),
        header: i18n.t("market.confirm.delete.header"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.markets.deleteMarket(market.value!).then((data: Market) => {
            market.value = Object.assign(new Market(), data);
            toast.add({
              severity: "success",
              summary: i18n.t("market.toast.delete.summary"),
              detail: i18n.t("market.toast.delete.detail"),
              life: 5000,
            });
            router.push({ path: "/markets" });
          });
        }
      });
    };

    return {
      i18n,
      market,
      saveMarket,
      deleteMarket,
      appUser,
      can: usersStore.can,
      exchanges,
    };
  },
});
