
import { defineComponent, ref, computed, onUpdated, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import { useUsersStore } from "@/store/users";
import Group from "@/models/users/Group";
import Menu from "@/models/menus/Menu";
import Permission from "@/models/users/Permission";

import InputSwitch from 'primevue/inputswitch';
import Utilisateur from "@/models/utilisateurs/Utilisateur";

export default defineComponent({
  name: "PermissionsMatrix",
  components: {
    InputSwitch,
  },
  props: {
    group: {
      type: Group,
    },
    user: {
      type: Utilisateur,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const usersStore = useUsersStore();
    const permissions = ref<Permission[]>();
    const userGroupsPermissionsId = ref<number[]>([]);
    const menus = ref<Menu[]>();

    class MatrixLine {
      model: string;
      view_permission?: MatrixPermission;
      add_permission?: MatrixPermission;
      change_permission?: MatrixPermission;
      delete_permission?: MatrixPermission;
      all_users_permission?: MatrixPermission;

      constructor(model: string) {
        this.model = model;
      }
    }

    onUpdated(async() => {
      if(props.user) {
        for(const groupId of props.user!.groups) {
          const userGroup = ref<Group>(new Group());
          userGroup.value.id = groupId;
          const data = await apiClient.groups.getGroup(userGroup.value);
          userGroup.value = Object.assign(new Group(), data);
          userGroupsPermissionsId.value = userGroupsPermissionsId.value!.concat(userGroup.value.permissions)
        }
      }
    })

    const initGoodPermisssion = (matrixLine: MatrixLine, permission: MatrixPermission) =>
    {
      if(permission.codename.startsWith('view_')) {
        matrixLine.view_permission = permission;
      } else if(permission.codename.startsWith('add_')) {
        matrixLine.add_permission = permission;
      } else if(permission.codename.startsWith('change_')) {
        matrixLine.change_permission = permission;
      } else if(permission.codename.startsWith('delete_')) {
        matrixLine.delete_permission = permission;
      } else if(permission.codename.startsWith('all_users_')) {
        matrixLine.all_users_permission = permission;
      }
    }

    class MatrixPermission {
      id: number;
      codename: string;
      isActive: boolean;
      isLocked: boolean;

      constructor(id: number, codename: string, isActive: boolean, isLocked: boolean) {
        this.id = id;
        this.codename = codename;
        this.isActive = isActive;
        this.isLocked = isLocked;
      }
    }

    const matrixLines = ref<MatrixLine[]>([]);
  
    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    apiClient.menus.getMenus().then((data: Menu[]) => {
      menus.value = data.map((menu) => Object.assign(new Menu(), menu));
    });

    const addMatrixPermission = (permission: Permission) => {
      if(props.user) {
        // Page des Utilisateurs
        return new MatrixPermission(
          permission.id!,
          permission.codename!,
          userGroupsPermissionsId.value!.includes(permission.id!) || props.user.user_permissions.includes(permission.id!),
          userGroupsPermissionsId.value!.includes(permission.id!)
        )
      } else {
        // Page des Groupes
        return new MatrixPermission(permission.id!, permission.codename!, props.group!.permissions.includes(permission.id!), false!);
      }
    }

    apiClient.groups.getPermissions({app_client: 'ef50b4b0-a355-475a-9ff7-8fa9c791a614'}).then((data: Permission[]) => {
      permissions.value = data.map((permission) => Object.assign(new Permission(), permission));
      permissions.value.sort((a, b) => (a.codename! < b.codename! ? -1 : 1));
      
      for(const permission of permissions.value) {
        const matrixLine = matrixLines.value.find(matrixLine => matrixLine.model == permission.content_type.model)
        if(matrixLine != undefined) {
          const newMatrixPermission = addMatrixPermission(permission);
          // props.group!.permissions.includes(permission.id!) => fonction(permission, groups.permissions, user.user_permissions) => 0 / 1 / 2 
          initGoodPermisssion(matrixLine, newMatrixPermission);
        }
        else {
          const newMatrixPermission = addMatrixPermission(permission);
          const newMatrixLine = new MatrixLine(permission.content_type.model);
          initGoodPermisssion(newMatrixLine, newMatrixPermission);
          matrixLines.value.push(newMatrixLine);
        }
      }

      for(const menu of menus.value!) {
        for(const category of menu.categories!) {
          for(const item of category.items!) {
            const matrixLine = matrixLines.value.find(elem => elem.model == item.model)
            item.matrixLine = matrixLine;
          }
        }
      }
    });

    const permissionChange = async(matrixPermission: MatrixPermission) => {
      if(props.user) {
        const index = props.user!.user_permissions.findIndex(permission => permission ==  matrixPermission.id);
        if(index > -1) {
          props.user!.user_permissions.splice(index!, 1);
        } else {
          props.user!.user_permissions.push(matrixPermission.id);
        }
      } else {
        const index = props.group!.permissions!.findIndex(permission => permission ==  matrixPermission.id);
        if(index > -1) {
          props.group!.permissions!.splice(index!, 1);
        } else {
          props.group!.permissions!.push(matrixPermission.id);
        }
      }
    }

    return {
      i18n,
      appUser,
      can: usersStore.can,
      permissions,
      matrixLines,
      permissionChange,
      menus,
    };
  },
});
