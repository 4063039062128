
import { defineComponent, ref, computed } from "vue";
import Card from "primevue/card";
import Audit from "@/models/audits/Audit";
import { useI18n } from "vue-i18n";
import moment from "moment";
import apiClient from "@/api-client";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  components: {
    Card,
    VueJsonPretty,
  },
  props: {
    auditId: {
      type: String,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const audit = ref<Audit>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    audit.value = new Audit();
    audit.value.id = props.auditId!;

    apiClient.audits.getAudit(audit.value!).then((data: Audit) => {
      audit.value = Object.assign(new Audit(), data);
      // audit.value.values_old = JSON.parse(JSON.stringify(data.values_old));
      // audit.value.values_new = JSON.parse(JSON.stringify(data.values_new));
    });

    return {
      i18n,
      moment,
      audit,
      appUser,
      can: usersStore.can,
    };
  },
});
