import { ErrorStateInterface } from '@/models/store'
/**
* @name initialErrorState
* @description
* The Error state instance with the initial default values
*/
export const initialErrorState: ErrorStateInterface = {
    error: {
        request_status: '',
        request_statusText: ''
    }
}