
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import CompteForm from '@/components/comptes/CompteForm.vue'

export default defineComponent({
  name: "CompteDetailPage",
  components: {
    CompteForm
  },
  setup() {
    const route = useRoute();
    const compteId = route.params.compteId =='null' ? null : route.params.compteId;

    return {
      compteId
    };
  },
});
