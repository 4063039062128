import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';
import AuditsPage from '../views/dashboard/AuditsPage.vue';
import AuditDetailPage from '../views/dashboard/AuditDetailPage.vue';
import Forbidden403Page from '../views/Forbidden403Page.vue';
import GroupDetailPage from '../views/dashboard/GroupDetailPage.vue';
import HomePage from '../views/dashboard/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import MarketsPage from '../views/dashboard/MarketsPage.vue';
import MarketDetailPage from '../views/dashboard/MarketDetailPage.vue';
import FeaturesPage from '../views/dashboard/FeaturesPage.vue';
import FeatureDetailPage from '../views/dashboard/FeatureDetailPage.vue';
import RobotsPage from '../views/dashboard/RobotsPage.vue';
import RobotDetailPage from '../views/dashboard/RobotDetailPage.vue';
import RobotTestPage from '../views/dashboard/RobotTestPage.vue';
import ResetPasswordPage from '../views/ResetPasswordPage.vue';
import SimulationUtilisateursPage from '../views/SimulationUtilisateursPage.vue';
import StartPage from '../views/StartPage.vue';
import UtilisateursPage from '../views/dashboard/UtilisateursPage.vue';
import UtilisateurDetailPage from '../views/dashboard/UtilisateurDetailPage.vue';
import ComptesPage from '../views/dashboard/ComptesPage.vue';
import CompteDetailPage from '../views/dashboard/CompteDetailPage.vue';
import CompteHistoPage from '../views/dashboard/CompteHistoPage.vue';

const routes: Array<RouteRecordRaw> = [
  // Dashboard
  //-------------------------------------------------------------------------------------------------------------------------------------
  {
    path: '/audits/:table:/:id',
    alias: ['/audits'],
    name: 'AuditsPage',
    component: AuditsPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_audit',
      breadcrumbItems: [
        { label: 'navigation.crypthune.home', to: '/' },
        { label: 'navigation.crypthune.auditListe', to: '/' }
      ]
    }
  },
  {
    path: '/audit/:id',
    name: 'AuditDetailPage',
    component: AuditDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_audit',
      breadcrumbItems: [
        { label: 'navigation.crypthune.home', to: '/' },
        { label: 'navigation.crypthune.auditDetail', to: '/' }
      ]
    }
  },
  {
    path: '/groups/:groupId',
    name: 'GroupDetailPage',
    component: GroupDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'app-clients.view_app-client',
      breadcrumbItems: [
        { label: 'navigation.crypthune.home', to: '/' },
        { label: 'navigation.crypthune.app_clients', to: '/app-clients' },
        { label: 'navigation.crypthune.detail', to: '/' }
      ]
    }
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      layout: 'DashboardLayout',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' }
      ]
    }
  },
  {
    path: '/markets',
    name: 'MarketsPage',
    component: MarketsPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'markets.view_market',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.markets', to: '/markets' }
      ]
    }
  },
  {
    path: '/markets/:marketId',
    name: 'MarketDetailPage',
    component: MarketDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_user',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.markets', to: '/markets' },
        { label: 'navigation.dashboard.detail', to: '/' }
      ]
    }
  },
  {
    path: '/features',
    name: 'FeaturesPage',
    component: FeaturesPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'features.view_feature',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.features', to: '/features' }
      ]
    }
  },
  {
    path: '/features/:featureId',
    name: 'FeatureDetailPage',
    component: FeatureDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_user',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.features', to: '/features' },
        { label: 'navigation.dashboard.detail', to: '/' }
      ]
    }
  },
  {
    path: '/robots',
    name: 'RobotsPage',
    component: RobotsPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'robots.view_robot',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.robots', to: '/robots' }
      ]
    }
  },
  {
    path: '/robots/:robotId',
    name: 'RobotDetailPage',
    component: RobotDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'robots.view_robot',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.robots', to: '/robots' },
        { label: 'navigation.dashboard.detail', to: '/' }
      ]
    }
  },
  {
    path: '/robots/:robotId/test',
    name: 'RobotTestPage',
    component: RobotTestPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'robots.view_robot',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.robots', to: '/robots' },
        { label: 'navigation.dashboard.detail', to: '/robots/:robotId' },
        { label: 'navigation.dashboard.robotstest', to: '/' }
      ]
    }
  },
  {
    path: '/comptes',
    name: 'ComptesPage',
    component: ComptesPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'comptes.view_robot',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.comptes', to: '/comptes' }
      ]
    }
  },
  {
    path: '/comptes/:compteId',
    name: 'CompteDetailPage',
    component: CompteDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'comptes.view_compte',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.comptes', to: '/comptes' },
        { label: 'navigation.dashboard.detail', to: '/' }
      ]
    }
  },
  {
    path: '/comptes/:compteId/histo',
    name: 'CompteHistoPage',
    component: CompteHistoPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'comptes.view_compte',
      breadcrumbItems: [
        { label: 'navigation.dashboard.home', to: '/' },
        { label: 'navigation.dashboard.comptes', to: '/comptes' },
        { label: 'navigation.dashboard.detail', to: '/' }
      ]
    }
  },
  {
    path: '/utilisateurs',
    name: 'UtilisateursPage',
    component: UtilisateursPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_user',
      breadcrumbItems: [
        { label: 'navigation.crypthune.home', to: '/' },
        { label: 'navigation.crypthune.users', to: '/utilisateurs' }
      ]
    }
  },
  {
    path: '/utilisateur/:utilisateurId',
    name: 'UtilisateurDetailPage',
    component: UtilisateurDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_user',
      breadcrumbItems: [
        { label: 'navigation.crypthune.home', to: '/' },
        { label: 'navigation.crypthune.users', to: '/utilisateurs' },
        { label: 'navigation.crypthune.detail', to: '/' }
      ]
    }
  },
  // Common
  //-------------------------------------------------------------------------------------------------------------------------------------
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/simulation-utilisateur/:userId',
    name: 'SimulationUtilisateur',
    component: SimulationUtilisateursPage,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/start/:id',
    name: 'Start',
    component: StartPage,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/forbidden403',
    name: 'Forbidden403',
    component: Forbidden403Page,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/resetpassword/:id',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    meta: {
      layout: 'PageLayout'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router