import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "locale-selector" }
const _hoisted_2 = { class: "locale-radio-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocaleFlagRadio = _resolveComponent("LocaleFlagRadio")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.availableLocales, (localeInfo, index) => {
        return (_openBlock(), _createBlock(_component_LocaleFlagRadio, {
          key: index,
          localeInfo: localeInfo,
          onClicked: _ctx.onFlagClicked
        }, null, 8, ["localeInfo", "onClicked"]))
      }), 128))
    ])
  ]))
}