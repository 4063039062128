import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "info-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["app-user-simulation-info", { 'app-user-simulation-info-active': _ctx.user.real_user }]
  }, [
    _createVNode("div", _hoisted_1, _toDisplayString(_ctx.i18n.t('app.userSimulationMessage')), 1)
  ], 2))
}