
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import Button from "primevue/button";

export default defineComponent({
  name: "Forbidden403Page",
  components: {
    Button,
  },
  setup() {
    const route = useRoute();
    const i18n = useI18n();
    const password = ref("");

    return {
      i18n,
    };
  },
});
