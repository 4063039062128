
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import Utilisateur from "@/models/utilisateurs/Utilisateur";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import Card from "primevue/card";
import InputSwitch from "primevue/inputswitch";
import { useUsersStore } from "@/store/users";
import MultiSelect from "primevue/multiselect";
import Group from "@/models/users/Group";
import PermissionsMatrix from '@/components/permissions/PermissionsMatrix.vue'

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    InputSwitch,
    MultiSelect,
    PermissionsMatrix,
  },
  props: {
    utilisateurId: {
      type: String,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const toast = useToast();
    const utilisateur = ref<Utilisateur>();
    const usersStore = useUsersStore();
    const groups = ref<Group[]>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    utilisateur.value = new Utilisateur();
    utilisateur.value.id = props.utilisateurId!;

    if (props.utilisateurId) {
      apiClient.utilisateurs
        .getUtilisateur(utilisateur.value)
        .then((data: Utilisateur) => {
          utilisateur.value = Object.assign(new Utilisateur(), data);
        });
    }

    apiClient.groups.getGroups().then((data: Array<Group>) => {
      groups.value = data;
      groups.value.sort(function(a, b) {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      });
    });

    const saveUtilisateur = () => {
      apiClient.utilisateurs
        .sendUtilisateur(utilisateur.value!)
        .then((data: Utilisateur) => {
          utilisateur.value = Object.assign(new Utilisateur(), data);
          toast.add({
            severity: "success",
            summary: i18n.t("user.toast.create.summary"),
            detail: i18n.t("user.toast.create.detail"),
            life: 5000,
          });
        });
    };

    return {
      i18n,
      utilisateur,
      saveUtilisateur,
      appUser,
      can: usersStore.can,
      groups,
    };
  },
});
