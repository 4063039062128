
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  components: {
  },
  props: {
  },
  setup() {
    const i18n = useI18n();
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });

    return {
      i18n,
      user,
    };
  },
});
