import Market from '@/models/markets/Market'
import { HttpClient } from '@/http-client'

class MarketsApiClient {
    urls = {
        getMarkets: process.env.VUE_APP_API_URL + '/api/markets',
        getMarket: process.env.VUE_APP_API_URL + '/api/markets/$(marketId)',
        postMarket: process.env.VUE_APP_API_URL + '/api/markets',
        putMarket: process.env.VUE_APP_API_URL + '/api/markets/$(marketId)',
        deleteMarket: process.env.VUE_APP_API_URL + '/api/markets/$(marketId)'
    }

    getMarkets(apiFilters?: any): Promise<Market[]> {
        const getParameters = {
            url: this.urls.getMarkets,
            filters: apiFilters,
        }
        return HttpClient.get<Market[]>(getParameters)
    }

    getMarket(market: Market): Promise<Market> {
        const getParameters = {
            url: this.urls.getMarket.replace('$(marketId)', market.id!)
        }
        return HttpClient.get<Market>(getParameters)
    }

    sendMarket(_market: Market): Promise<Market> {
        const market = Object.assign({}, _market);
        if (market.id != 'notSet') {
            const getParameters = {
                url: this.urls.putMarket.replace('$(marketId)', market.id!),
                payload: market,
            }
            return HttpClient.put<Market>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postMarket,
                payload: market,
            }
            return HttpClient.post<Market>(getParameters)
        }
    }

    deleteMarket(market: Market): Promise<Market> {
        const getParameters = {
            url: this.urls.deleteMarket.replace('$(marketId)', market.id!),
        }
        return HttpClient.delete<Market>(getParameters)
    }
}

export default new MarketsApiClient;