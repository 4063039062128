import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "comptes-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComptesList = _resolveComponent("ComptesList")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_ComptesList, { uuid: "a8ddbb5c-cdc7-4050-ab4f-a4a4feb465dd" })
      ]),
      _: 1
    })
  ]))
}