export default class Robot {
  id: string;
  name: string;
  description: string;
  training_date_debut: Date;
  training_date_fin: Date;
  params: string;
  status: string;
  training_market: any;
  features: any;
  config_neat: any;
  training_result: any;
  no_gen_en_cours: number;
  gener_models: string;
  gener_prod: string;
  valid_date_fin: Date;
  date_create: Date;

  constructor(x?: any) {
    this.id = x && x.id ? x.id : 'notSet';
    this.name = x && x.name ? x.name : undefined;
    this.description = x && x.description ? x.description : undefined;
    this.training_date_debut = x && x.training_date_debut ? new Date(x.training_date_debut) : new Date;
    this.training_date_fin = x && x.training_date_fin ? new Date(x.training_date_fin) : new Date;
    this.params = x && x.params ? x.params : undefined;
    this.status = x && x.status ? x.status : undefined;
    this.training_market = x && x.training_market ? x.training_market : undefined;
    this.features = x && x.features ? x.features : undefined;
    this.config_neat = x && x.config_neat ? x.config_neat : undefined;
    this.training_result = x && x.training_result ? x.training_result : undefined;
    this.no_gen_en_cours = x && x.no_gen_en_cours ? x.no_gen_en_cours : undefined;
    this.gener_models = x && x.gener_models ? x.gener_models : undefined;
    this.gener_prod = x && x.gener_prod ? x.gener_prod : undefined;
    this.valid_date_fin = x && x.valid_date_fin ? new Date(x.valid_date_fin) : new Date;
    this.date_create = x && x.date_create ? new Date(x.date_create) : new Date;
  }

  get GenerationProgressPct(): number {
    if (this.params != undefined) {
      return this.no_gen_en_cours * 100 / JSON.parse(this.params)['nb_generations'];
    } else {
      return 0;
    }
  }
}