import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { rootStore } from './store';
import { i18n } from './translations';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import AppLayout from './layouts/AppLayout.vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaw, faEdit, faBug, faKey, faEye, faAddressCard, faTasks,
  faCalendarAlt, faUser, faUserCheck, faUserTag, faUserClock, faUserTie, faBuilding,
  faLaptopHouse, faUsers, faUserCog, faChartPie, faNetworkWired, faUmbrellaBeach,
  faCreditCard, faUnlockAlt, faCar, faSnowplow, faHouseUser, faFolderOpen,
  faCalendarCheck, faFileAlt, faFire, faUserFriends, faPeopleArrows, faPoll, faTruck, faTag, faStore,
  faFileInvoiceDollar, faComment, faHome, faWarehouse,faGasPump, faMoneyCheck, faFileContract, faAsterisk,
  faCommentDots, faClipboardList, faSubway, faCodeBranch, faHandshake, faBars, faCircle,
  faFilePowerpoint, faPercentage, faWallet, faFileInvoice, faCalculator, faCity,
  faFolder, faTrashAlt, faCommentDollar, faHardHat, faEuroSign, faAtlas, faSitemap, faHammer, faTicketAlt, faEraser, faMap, faFileImport,
  faComments, faNewspaper, faBoxes, faCube, faCommentAlt, faFileSignature, faArrowCircleRight, faChartArea, faRobot, faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// icons fontawesome disponibles sur l'app
library.add(faPaw, faEdit, faBug, faKey, faEye, faAddressCard, faTasks, faCalendarAlt,
  faComment,
  faUser, faUserCheck, faUserTag, faUserClock, faUserTie, faBuilding, faLaptopHouse,
  faUsers, faUserCog, faChartPie, faNetworkWired, faUmbrellaBeach, faCreditCard,
  faUnlockAlt, faCar, faSnowplow, faHouseUser, faFolderOpen, faUserClock, faCalendarCheck,
  faFileAlt, faFire, faUserFriends, faPeopleArrows, faPoll, faTruck, faTag, faStore,
  faFileInvoiceDollar, faHome, faWarehouse, faGasPump, faMoneyCheck, faFileContract, faAsterisk,
  faCommentDots, faClipboardList, faSubway, faCodeBranch, faHandshake, faBars,
  faCircle, faFilePowerpoint, faPercentage, faWallet, faFileInvoice, faCalculator, faCity,
  faFolder, faTrashAlt, faCommentDollar, faHardHat, faEuroSign, faAtlas, faSitemap, faHammer, faTicketAlt, faEraser , faMap, faFileImport,
  faComments, faNewspaper, faBoxes, faCube, faCommentAlt, faFileSignature, faArrowCircleRight, faChartArea, faRobot, faClone);

const locale = {locale: {
  startsWith: 'Starts with',
  contains: 'Contains',
  notContains: 'Not contains',
  endsWith: 'Ends with',
  equals: 'Equals',
  notEquals: 'Not equals',
  noFilter: 'No Filter',
  lt: 'Less than',
  lte: 'Less than or equal to',
  gt: 'Greater than',
  gte: 'Greater than or equal to',
  dateIs: 'Date is',
  dateIsNot: 'Date is not',
  dateBefore: 'Date is before',
  dateAfter: 'Date is after',
  clear: 'Clear',
  apply: 'Apply',
  matchAll: 'Match All',
  matchAny: 'Match Any',
  addRule: 'Add Rule',
  removeRule: 'Remove Rule',
  accept: 'Yes',
  reject: 'No',
  choose: 'Choose',
  upload: 'Upload',
  cancel: 'Cancel',
  dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
  monthNames: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Decembre"],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  today: 'Aujourd\'hui',
  weekHeader: 'Wk',
  firstDayOfWeek: 1,
  dateFormat: 'dd/mm/yy',
  weak: 'Weak',
  medium: 'Medium',
  strong: 'Strong',
  passwordPrompt: 'Enter a password',
  emptyFilterMessage: 'No results found',
  emptyMessage: 'No available options'
}}

const FlagIconsScss = {
  install() {
    require('./assets/scss/flag-icons/index.scss')
  }
}

createApp(App)
  .use(router)
  .use(rootStore)
  .use(i18n)
  .use(FlagIconsScss)
  .use(PrimeVue, locale)
  .use(ToastService)
  .use(ConfirmationService)
  .component('Toast',Toast)
  .component('ConfirmDialog',ConfirmDialog)
  .component('AppLayout', AppLayout)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app');