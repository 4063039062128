import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "compte-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompteForm = _resolveComponent("CompteForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_CompteForm, { compteId: _ctx.compteId }, null, 8, ["compteId"])
  ]))
}