import Group from '@/models/users/Group'
import { HttpClient } from '@/http-client'
import Permission from '@/models/users/Permission'

class GroupsApiClient {
    urls = {
        getGroups: process.env.VUE_APP_API_URL + '/api/groups',
        getGroup: process.env.VUE_APP_API_URL + '/api/groups/$(groupId)',
        postGroup: process.env.VUE_APP_API_URL + '/api/groups',
        putGroup: process.env.VUE_APP_API_URL + '/api/groups/$(groupId)',
        deleteGroup: process.env.VUE_APP_API_URL + '/api/groups/$(groupId)',
    
        getPermissions: process.env.VUE_APP_API_URL + '/api/permissions',
    }

    getGroups(apiFilters?: any): Promise<Group[]> {
        const getParameters = {
            url: this.urls.getGroups,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Group[]>(getParameters)
    }

    getGroup(group: Group): Promise<Group> {
        const getParameters = {
            url: this.urls.getGroup.replace('$(groupId)', group.id!),
            requiresToken: false,
        }
        return HttpClient.get<Group>(getParameters)
    }

    sendGroup(_group: Group): Promise<Group> {
        const group = Object.assign({}, _group);
        if (group.id != 'notSet') {
            const getParameters = {
                url: this.urls.putGroup.replace('$(groupId)', group.id!),
                requiresToken: false,
                payload: group,
            }
            return HttpClient.put<Group>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postGroup,
                requiresToken: false,
                payload: group,
            }
            return HttpClient.post<Group>(getParameters)
        }
    }

    deleteGroup(group: Group): Promise<Group> {
        const getParameters = {
            url: this.urls.deleteGroup.replace('$(groupId)', group.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Group>(getParameters)
    }

    getPermissions(apiFilters?: any): Promise<Permission[]> {
        const getParameters = {
            url: this.urls.getPermissions,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Permission[]>(getParameters)
    }
}

export default new GroupsApiClient;