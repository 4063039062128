import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "p-field p-grid"
}
const _hoisted_3 = {
  for: "id",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_4 = { class: "p-col-12 p-md-10" }
const _hoisted_5 = { class: "p-field p-grid" }
const _hoisted_6 = {
  for: "email",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_7 = { class: "p-col-12 p-md-10" }
const _hoisted_8 = { class: "p-field p-grid" }
const _hoisted_9 = {
  for: "email",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_10 = { class: "p-col-12 p-md-10" }
const _hoisted_11 = { class: "p-field p-grid" }
const _hoisted_12 = {
  for: "email",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_13 = { class: "p-col-12 p-md-10" }
const _hoisted_14 = { class: "p-field p-grid" }
const _hoisted_15 = {
  for: "permissions",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_16 = { class: "p-col-12 p-md-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_MultiSelect = _resolveComponent("MultiSelect")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_PermissionsMatrix = _resolveComponent("PermissionsMatrix")
  const _component_Button = _resolveComponent("Button")
  const _component_router_link = _resolveComponent("router-link")
  const _component_Card = _resolveComponent("Card")
  const _component_AdresseForm = _resolveComponent("AdresseForm")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_ConfirmDialog),
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
            ? (_openBlock(), _createBlock("div", _hoisted_2, [
                _createVNode("label", _hoisted_3, [
                  _createVNode(_component_font_awesome_icon, { icon: "key" })
                ]),
                _createVNode("div", _hoisted_4, [
                  _createVNode(_component_InputText, {
                    disabled: "",
                    modelValue: _ctx.utilisateur.id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.utilisateur.id = $event)),
                    id: "id",
                    type: "text"
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_5, [
            _createVNode("label", _hoisted_6, _toDisplayString(_ctx.i18n.t("user.email")), 1),
            _createVNode("div", _hoisted_7, [
              _createVNode(_component_InputText, {
                disabled: "",
                modelValue: _ctx.utilisateur.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.utilisateur.email = $event)),
                id: "email",
                type: "text"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_8, [
            _createVNode("label", _hoisted_9, _toDisplayString(_ctx.i18n.t("user.groups")), 1),
            _createVNode("div", _hoisted_10, [
              _createVNode(_component_MultiSelect, {
                modelValue: _ctx.utilisateur.groups,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.utilisateur.groups = $event)),
                options: _ctx.groups,
                optionLabel: "name",
                optionValue: "id",
                placeholder: _ctx.i18n.t('user.selectGroups'),
                filter: true,
                showToggleAll: false
              }, null, 8, ["modelValue", "options", "placeholder"])
            ])
          ]),
          _createVNode("div", _hoisted_11, [
            _createVNode("label", _hoisted_12, _toDisplayString(_ctx.i18n.t("user.active")), 1),
            _createVNode("div", _hoisted_13, [
              _createVNode(_component_InputSwitch, {
                modelValue: _ctx.utilisateur.is_active,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.utilisateur.is_active = $event)),
                disabled: _ctx.utilisateur.id == _ctx.appUser.id
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_14, [
            _createVNode("label", _hoisted_15, _toDisplayString(_ctx.i18n.t("group.permissions")), 1),
            _createVNode("div", _hoisted_16, [
              (_ctx.userId != 'notSet')
                ? (_openBlock(), _createBlock(_component_PermissionsMatrix, {
                    key: 0,
                    group: undefined,
                    user: _ctx.utilisateur
                  }, null, 8, ["user"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          onClick: _ctx.saveUtilisateur,
          label: _ctx.i18n.t('app.enregistrer'),
          icon: "pi pi-save",
          iconPos: "right"
        }, null, 8, ["onClick", "label"]),
        (_ctx.can('users.view_audit'))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {
          name: 'AuditsPage',
          params: { table: 'users_user', id: _ctx.utilisateur.id },
        }
            }, {
              default: _withCtx(() => [
                (_ctx.utilisateurId != 'notSet')
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: _ctx.i18n.t('app.audit'),
                      icon: "pi pi-file-excel",
                      iconPos: "right",
                      class: "p-button-help"
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        (
          _ctx.utilisateur.is_active &&
          _ctx.can('exec_usersimulation') &&
          _ctx.utilisateur.id != _ctx.appUser.id &&
          _ctx.appUser.real_user == null
        )
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: '/simulation-utilisateur/' + _ctx.utilisateur.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, { class: "p-button-secondary" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.i18n.t('user.simuler')) + "   ", 1),
                    _createVNode(_component_font_awesome_icon, { icon: "paw" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        (
          _ctx.utilisateur.is_active &&
          _ctx.can('exec_usersimulation') &&
          _ctx.utilisateur.id != _ctx.appUser.id &&
          _ctx.appUser.real_user != null
        )
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              class: "p-button-secondary",
              disabled: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('user.simuler')) + "   ", 1),
                _createVNode(_component_font_awesome_icon, { icon: "paw" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.utilisateur.adresses, (adresse) => {
      return (_openBlock(), _createBlock("div", {
        key: adresse.id
      }, [
        _createVNode(_component_AdresseForm, {
          adresseId: adresse.id
        }, null, 8, ["adresseId"])
      ]))
    }), 128))
  ], 64))
}