
import { defineComponent, ref, onMounted, watch } from "vue";
import Audit from "@/models/audits/Audit";
import apiClient from "@/api-client";
import { useI18n } from "vue-i18n";
import AuditsList from "@/components/audits/AuditsList.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "AuditsPage",
  components: {
    AuditsList,
  },
  setup() {
    const i18n = useI18n();
    const audits = ref<Audit[]>();
    const route = useRoute();
    let filters: any;
    if (route.params.id != "all") {
      filters = { entite_id: route.params.id };
    } else {
      filters = { table: route.params.table };
    }

    onMounted(() => {
      apiClient.audits.getAudits(filters).then((data: Audit[]) => {
        audits.value = data;
      });
    });

    watch(
      () => route.params,
      () => {
        apiClient.audits.getAudits({}).then((data: Audit[]) => {
          audits.value = data;
        });
      }
    );

    return {
      audits,
      i18n,
      filters,
    };
  },
});
