
import { defineComponent, ref, computed } from "vue";
import Market from "@/models/markets/Market";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import MarketsList from "@/components/markets/MarketsList.vue";

export default defineComponent({
  name: "MarketsPage",
  components: {
    Card,
    MarketsList,
  },
  setup() {
    const i18n = useI18n();
    const Markets = ref<Market[]>();

    return {
      Markets,
      i18n,
    };
  },
});
