
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Group from "@/models/users/Group";
import Permission from "@/models/users/Permission";
//import MultiSelect from 'primevue/multiselect';
import PermissionsMatrix from '@/components/permissions/PermissionsMatrix.vue'

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    //MultiSelect,
    PermissionsMatrix,
  },
  props: {
    groupId: {
      type: String,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const toast = useToast();
    const confirm = useConfirm();
    const group = ref<Group>();
    const usersStore = useUsersStore();
    const permissions = ref<Permission[]>();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    apiClient.groups.getPermissions({app_client: 'ef50b4b0-a355-475a-9ff7-8fa9c791a614'}).then((data: Permission[]) => {
      permissions.value = data.map((permission) => Object.assign(new Permission(), permission));
      permissions.value.sort((a, b) => (a.codename! < b.codename! ? -1 : 1));
    });

    group.value = new Group();
    group.value.id = props.groupId!;

    if (props.groupId != "notSet") {
      apiClient.groups.getGroup(group.value).then((data: Group) => {
        group.value = Object.assign(new Group(), data);
      });
    }

    const saveGroup = () => {
      apiClient.groups.sendGroup(group.value!).then((data: Group) => {
        group.value = Object.assign(new Group(), data);
        router.back();
        toast.add({
          severity: "success",
          summary: i18n.t("group.toast.create.summary"),
          detail: i18n.t("group.toast.create.detail"),
          life: 5000,
        });
      });
    };

    const deleteGroup = () => {
      confirm.require({
        message: i18n.t("group.confirm.delete.message"),
        header: i18n.t("group.confirm.delete.header"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.groups.deleteGroup(group.value!).then((data: Group) => {
            group.value = Object.assign(new Group(), data);
            toast.add({
              severity: "success",
              summary: i18n.t("group.toast.delete.summary"),
              detail: i18n.t("group.toast.delete.detail"),
              life: 5000,
            });
            router.push({ path: "/groups" });
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      i18n,
      group,
      saveGroup,
      deleteGroup,
      appUser,
      can: usersStore.can,
      permissions,
    };
  },
});
