
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Feature from "@/models/features/Feature";
import Textarea from 'primevue/textarea';

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    Textarea,
  },
  props: {
    featureId: {
      type: String,
    }
  },
  setup(props) {
    const i18n = useI18n();
    const toast = useToast();
    const confirm = useConfirm();
    const feature = ref<Feature>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    feature.value = new Feature();
    feature.value.id = props.featureId!;

    if (props.featureId != "notSet") {
      apiClient.features.getFeature(feature.value).then((data: Feature) => {
        feature.value = Object.assign(new Feature(), data);
      });
    }

    const saveFeature = () => {
      apiClient.features.sendFeature(feature.value!).then((data: Feature) => {
        feature.value = Object.assign(new Feature(), data);
        router.back();
        toast.add({
          severity: "success",
          summary: i18n.t("feature.toast.create.summary"),
          detail: i18n.t("feature.toast.create.detail"),
          life: 5000,
        });
      });
    };

    const deleteFeature = () => {
      confirm.require({
        message: i18n.t("feature.confirm.delete.message"),
        header: i18n.t("feature.confirm.delete.header"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.features.deleteFeature(feature.value!).then((data: Feature) => {
            feature.value = Object.assign(new Feature(), data);
            toast.add({
              severity: "success",
              summary: i18n.t("feature.toast.delete.summary"),
              detail: i18n.t("feature.toast.delete.detail"),
              life: 5000,
            });
            router.push({ path: "/features" });
          });
        }
      });
    };

    return {
      i18n,
      feature,
      saveFeature,
      deleteFeature,
      appUser,
      can: usersStore.can,
    };
  },
});
