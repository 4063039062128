
import { defineComponent, computed, ref } from "vue";
import BreadcrumbItem from './BreadcrumbItem.vue';
import SplitButton from "primevue/splitbutton";
import LocaleSelector from "@/components/locale-selector/LocaleSelector.component.vue";
import { MutationType } from "@/models/store";
import { useLocalesStore } from "@/store/locales";
import { useI18n } from "vue-i18n";
import router from "@/router";
import { useRoute } from "vue-router";
import { useUsersStore } from "@/store/users";
import { LocaleInfoInterface } from "@/models/localization/LocaleInfo.interface";

export default defineComponent({
    props: {
        model: {
            type: Array,
            default: null
        },
        home: {
            type: null,
            default: null
        },
        toggleDisplayMenu: Function,
    },
    components: {
        BreadcrumbItem,
        LocaleSelector,
        SplitButton,
    },
    setup() {
        const route = useRoute();
        const i18n = useI18n();
        const localesStore = useLocalesStore();
        const usersStore = useUsersStore();
        const availableLocales = computed(() => {
            return localesStore.state.availableLocales;
        });
        const user = computed(() => {
            return usersStore.state.appUser;
        });

        const items = null;

        const appUserItems = computed(() => {
            return [
                {
                    label: i18n.t("navigation.dashboard.logout"),
                    icon: "pi pi-sign-out",
                    command: () => {
                        usersStore.action(MutationType.users.logoutAppUser, {});
                        router.push({ path: "/login" });
                    }
                }
            ];
        });

        const onLocaleClicked = (localeInfo: LocaleInfoInterface) => {
            localesStore.action(MutationType.locales.selectLocale, localeInfo.locale);
        };

        return {
            availableLocales,
            onLocaleClicked,
            appUserItems,
            user,
            items,
        };
    },
})
