
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import FeatureForm from '@/components/features/FeatureForm.vue'

export default defineComponent({
  name: "FeatureDetailPage",
  components: {
    FeatureForm
  },
  setup() {
    const route = useRoute();
    const featureId = route.params.featureId =='null' ? null : route.params.featureId;

    return {
      featureId
    };
  },
});
