import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "audit-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuditsList = _resolveComponent("AuditsList")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_AuditsList, {
      audits: _ctx.audits,
      filter: _ctx.filters
    }, null, 8, ["audits", "filter"])
  ]))
}