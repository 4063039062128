import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "robot-test" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RobotTestForm = _resolveComponent("RobotTestForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_RobotTestForm, { robotId: _ctx.robotId }, null, 8, ["robotId"])
  ]))
}