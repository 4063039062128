
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import MarketForm from '@/components/markets/MarketForm.vue'

export default defineComponent({
  name: "MarketDetailPage",
  components: {
    MarketForm
  },
  setup() {
    const route = useRoute();
    const marketId = route.params.marketId =='null' ? null : route.params.marketId;

    return {
      marketId
    };
  },
});
