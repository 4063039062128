import Menu from '@/models/menus/Menu'
import { HttpClient } from '@/http-client'

class MenusApiClient {
    urls = {
        getMenus: process.env.VUE_APP_API_URL + '/api/menus',
        getMenu: process.env.VUE_APP_API_URL + '/api/menus/$(menuId)',
        postMenu: process.env.VUE_APP_API_URL + '/api/menus',
        putMenu: process.env.VUE_APP_API_URL + '/api/menus/$(menuId)',
        deleteMenu: process.env.VUE_APP_API_URL + '/api/menus/$(menuId)'
    }

    getMenus(apiFilters?: any): Promise<Menu[]> {
        const getParameters = {
            url: this.urls.getMenus,
            requiresToken: false,
        }
        return HttpClient.get<Menu[]>(getParameters)
    }

    getMenu(menu: Menu): Promise<Menu> {
        const getParameters = {
            url: this.urls.getMenu.replace('$(menuId)', menu.id!),
            requiresToken: false,
        }
        return HttpClient.get<Menu>(getParameters)
    }

    sendMenu(_menu: Menu): Promise<Menu> {
        const menu = Object.assign({}, _menu);
        if (menu.id != 'notSet') {
            const getParameters = {
                url: this.urls.putMenu.replace('$(menuId)', menu.id!),
                requiresToken: false,
                payload: menu,
            }
            return HttpClient.put<Menu>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postMenu,
                requiresToken: false,
                payload: menu,
            }
            return HttpClient.post<Menu>(getParameters)
        }
    }

    deleteMenu(menu: Menu): Promise<Menu> {
        const getParameters = {
            url: this.urls.deleteMenu.replace('$(menuId)', menu.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Menu>(getParameters)
    }
}

export default new MenusApiClient;