import Compte from '@/models/comptes/Compte'
import Ordre from '@/models/comptes/Compte'
import Asset from '@/models/comptes/Asset'
import Histo from '@/models/comptes/Histo'
import { HttpClient } from '@/http-client'

class ComptesApiClient {
    urls = {
        getComptes: process.env.VUE_APP_API_URL + '/api/comptes',
        getCompte: process.env.VUE_APP_API_URL + '/api/comptes/$(compteId)',
        postCompte: process.env.VUE_APP_API_URL + '/api/comptes',
        putCompte: process.env.VUE_APP_API_URL + '/api/comptes/$(compteId)',
        deleteCompte: process.env.VUE_APP_API_URL + '/api/comptes/$(compteId)',
        getOrdres: process.env.VUE_APP_API_URL + '/api/comptes/$(compteId)/ordres',
        getAssets: process.env.VUE_APP_API_URL + '/api/comptes/$(compteId)/assets',
        getHisto: process.env.VUE_APP_API_URL + '/api/comptes/$(compteId)/histo',
    }

    getComptes(apiFilters?: any): Promise<Compte[]> {
        const getParameters = {
            url: this.urls.getComptes,
            filters: apiFilters,
        }
        return HttpClient.get<Compte[]>(getParameters)
    }

    getCompte(compte: Compte): Promise<Compte> {
        const getParameters = {
            url: this.urls.getCompte.replace('$(compteId)', compte.id!)
        }
        return HttpClient.get<Compte>(getParameters)
    }

    sendCompte(_compte: Compte): Promise<Compte> {
        const compte = Object.assign({}, _compte);
        if (compte.id != 'notSet') {
            const getParameters = {
                url: this.urls.putCompte.replace('$(compteId)', compte.id!),
                payload: compte,
            }
            return HttpClient.put<Compte>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postCompte,
                payload: compte,
            }
            return HttpClient.post<Compte>(getParameters)
        }
    }

    deleteCompte(compte: Compte): Promise<Compte> {
        const getParameters = {
            url: this.urls.deleteCompte.replace('$(compteId)', compte.id!),
        }
        return HttpClient.delete<Compte>(getParameters)
    }

    getOrdres(compte: Compte): Promise<Ordre[]> {
        const getParameters = {
            url: this.urls.getOrdres.replace('$(compteId)', compte.id!)
        }
        return HttpClient.get<Ordre[]>(getParameters)
    }

    getAssets(compte: Compte, apiFilters?: any): Promise<Asset[]> {
        const getParameters = {
            url: this.urls.getAssets.replace('$(compteId)', compte.id!),
            filters: apiFilters,
        }
        return HttpClient.get<Asset[]>(getParameters)
    }

    getHisto(compte: Compte, apiFilters?: any): Promise<Histo> {
        const getParameters = {
            url: this.urls.getHisto.replace('$(compteId)', compte.id!),
            filters: apiFilters,
        }
        return HttpClient.get<Histo>(getParameters)
    }

}

export default new ComptesApiClient;