
import { defineComponent, watch, computed, ref, onBeforeMount } from "vue"
import { useErrorStore } from "@/store/error";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useUsersStore } from "./store/users";

export default defineComponent({
  name: "App",
  setup() {
    const usersStore = useUsersStore();
    const errorStore = useErrorStore();
    const toast = useToast();
    const i18n = useI18n();
    const mode = ref<string>("light")
    const styleLink = document.getElementById("theme-link")

    const user = computed(() => {
      return usersStore.state.appUser;
    });

    watch(
      () => errorStore.state.error,
      () => {
        toast.add({
          severity:'error',
          summary: i18n.t('error.serverError'),
          detail: errorStore.state.error?.request_status ? i18n.t('error.' + errorStore.state.error?.request_status) : i18n.t('error.serverOffline'),
          life: 3000});
      }
    );

    watch(
      () => user.value.profil && user.value.profil.dark_mode,
      () => {
        if(user.value.profil && user.value.profil.dark_mode){
          mode.value = "dark"
          styleLink!.setAttribute("href", "https://unpkg.com/primevue/resources/themes/bootstrap4-dark-blue/theme.css")

        }else{
          mode.value = "light"
          styleLink!.setAttribute("href", "https://unpkg.com/primevue/resources/themes/bootstrap4-light-blue/theme.css")
        }
      }
    )
    return {mode};
  }
});
