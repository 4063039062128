
import { computed, defineComponent, ref } from "vue";
import Market from '@/models/markets/Market';
import { useI18n } from "vue-i18n";
import apiClient from "@/api-client";
import Button from "primevue/button";
import { useUsersStore } from "@/store/users";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";

export default defineComponent({
  name: "MarketsList",
  components: {
    Button,
    DataTable,
    Column,
  },
  props: {
    uuid: {
      type: String,
    },
  },
  setup() {
    const i18n = useI18n();
    const markets = ref<Market[]>();
    const loading = ref(true);
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });
   
    loading.value = true;
    apiClient.markets.getMarkets().then((data: Market[]) => {
      markets.value = data.map((market) => Object.assign(new Market(), market));
      loading.value = false;
    });

    return {
      i18n,
      markets,
      loading,
      can: usersStore.can,
      appUser,
      moment,
    };
  },
});
