import Utilisateur from "../utilisateurs/Utilisateur"

export default class Audit {
    id: string
    user: Utilisateur;
    real_user: Utilisateur;
    date_action: Date;
    action: string;
    interface: string;
    table: string;
    entite_id: string;
    entite_description: string;
    values_old?: JSON;
    values_new?: JSON;
    description: string;
  
    constructor() {
        this.id = '';
        this.user = new Utilisateur();
        this.real_user = new Utilisateur();
        this.date_action = new Date();
        this.action = '';
        this.interface = '';
        this.table = '';
        this.entite_id = '';
        this.entite_description = '';
        this.values_old = undefined;
        this.values_new = undefined;
        this.description = '';
    }
}