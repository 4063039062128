export default class RobotFeature {
    id: string;
    robot: any;
    name: string;
    code: string;
    description: string;
  
    constructor(x?: any) {
      this.id = x && x.id ? x.id : 'notSet';
      this.robot = x && x.robot ? x.robot : undefined;
      this.name = x && x.name ? x.name : undefined;
      this.code = x && x.code ? x.code : undefined;
      this.description = x && x.description ? x.description : undefined;
    }
  }