import auditsApiClient from './audits/AuditsApiClient';
import choicesApiClient from './choices/ChoicesApiClient';
import exchangesApiClient from './exchanges/ExchangesApiClient';
import groupsApiClient from './groups/GroupsApiClient';
import marketsApiClient from './markets/MarketsApiClient';
import featuresApiClient from './features/FeaturesApiClient';
import robotsApiClient from './robots/RobotsApiClient';
import menusApiClient from './menus/MenusApiClient';
import usersApiClient from './users/UsersApiClient';
import utilisateursApiClient from './utilisateurs/UtilisateursApiClient';
import comptesApiClient from './comptes/ComptesApiClient';


// create an instance of our main ApiClient that wraps the live child clients
const apiLiveClient = {
    audits: auditsApiClient,
    choices: choicesApiClient,
    exchanges: exchangesApiClient,
    groups: groupsApiClient,
    markets: marketsApiClient,
    features: featuresApiClient,
    robots: robotsApiClient,
    menus: menusApiClient,
    users: usersApiClient,
    utilisateurs: utilisateursApiClient,
    comptes: comptesApiClient,
}
// export our instance
export default apiLiveClient