import { rootStore, dispatchModuleAction } from '../root'
import { StoreModuleNames, ErrorStateInterface } from '@/models/store'
/**
* @name errorStore
* @description
* The error store wrapper that returns the errorState and exposes a generic action<T> method
*/
const errorStore = {
    get state(): ErrorStateInterface {
        return rootStore.state.errorState
    },
    action<T>(actionName: string, params?: T): void {
        dispatchModuleAction(StoreModuleNames.errorState, actionName, params)
    }
}
// export our wrapper using the composition API convention (i.e. useXYZ)
export const useErrorStore = () => {
    return errorStore
}