export default class Permission {
    id: number;
    name?: string;
    content_type?: any;
    codename?: string;
  
    constructor() {
      this.id = -1;
      this.name = undefined;
      this.content_type = undefined;
      this.codename = undefined;
    }
}