
import { defineComponent, computed } from "vue";
import PageLayout from "./PageLayout.vue";
import DashboardLayout from "./DashboardLayout.vue";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import router from "@/router";

export default defineComponent({
  name: "AppLayout",
  setup() {
    const layout = shallowRef(PageLayout);
    const route = useRoute();
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });
    router.beforeEach((to, from, next) => {
      if (to.name != 'Login' && user.value.token == "" && to.meta.layout == "DashboardLayout") {
        next({ name: 'Login' });
      } 
      else {
        const permissions: string = typeof to.meta.permissions == 'string' ? to.meta.permissions : '';
        if (permissions != '' && !usersStore.can(permissions)) {
          next({ name: 'Forbidden403' });
        } else {
          next();
        }
      }
    })

    if (usersStore.state.appUser.token == "" && localStorage.getItem("appUser")) {
      usersStore.action(MutationType.users.loadFromLocalstorageAppUser, {
        appUser: JSON.parse(localStorage.getItem("appUser")!),
      });
    }

    watch(
      () => route.meta,
        (meta) => {
        switch (meta.layout) {
          case "DashboardLayout":
            layout.value = DashboardLayout;
            break;
          case "PageLayout":
            layout.value = PageLayout;
            break;
        }
      },
      { immediate: false }
    );
    
    return { layout };
  },
});
