
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import RobotForm from '@/components/robots/RobotForm.vue'

export default defineComponent({
  name: "RobotDetailPage",
  components: {
    RobotForm
  },
  setup() {
    const route = useRoute();
    const robotId = route.params.robotId =='null' ? null : route.params.robotId;

    return {
      robotId
    };
  },
});
