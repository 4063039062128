import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "robot-form" }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "p-field p-grid"
}
const _hoisted_4 = {
  for: "id",
  class: "p-col-4"
}
const _hoisted_5 = { class: "p-col-8" }
const _hoisted_6 = { class: "p-field p-grid" }
const _hoisted_7 = {
  for: "name",
  class: "p-col-4"
}
const _hoisted_8 = { class: "p-col-8" }
const _hoisted_9 = { class: "p-field p-grid" }
const _hoisted_10 = {
  for: "description",
  class: "p-col-4"
}
const _hoisted_11 = { class: "p-col-8" }
const _hoisted_12 = { class: "p-field p-grid" }
const _hoisted_13 = {
  for: "generation",
  class: "p-col-4"
}
const _hoisted_14 = { class: "p-col-8" }
const _hoisted_15 = { class: "p-field p-grid" }
const _hoisted_16 = {
  for: "training_market",
  class: "p-col-4"
}
const _hoisted_17 = { class: "p-col-8" }
const _hoisted_18 = { class: "p-field p-grid" }
const _hoisted_19 = {
  for: "training_date_deb",
  class: "p-col-4"
}
const _hoisted_20 = { class: "p-col-7" }
const _hoisted_21 = { class: "p-col-1" }
const _hoisted_22 = { class: "p-field p-grid" }
const _hoisted_23 = {
  for: "training_date_fin",
  class: "p-col-4"
}
const _hoisted_24 = { class: "p-col-8" }
const _hoisted_25 = { class: "p-field p-grid" }
const _hoisted_26 = {
  for: "test_result",
  class: "p-col-4"
}
const _hoisted_27 = { class: "p-col-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Button = _resolveComponent("Button")
  const _component_Textarea = _resolveComponent("Textarea")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode("label", _hoisted_4, [
                  _createVNode(_component_font_awesome_icon, { icon: "key" })
                ]),
                _createVNode("div", _hoisted_5, [
                  _createVNode(_component_InputText, {
                    disabled: "",
                    modelValue: _ctx.robot.id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.robot.id = $event)),
                    id: "id",
                    type: "text"
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_6, [
            _createVNode("label", _hoisted_7, _toDisplayString(_ctx.i18n.t("robots.name")), 1),
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.robot.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.robot.name = $event)),
                id: "name",
                type: "text",
                disabled: true
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_9, [
            _createVNode("label", _hoisted_10, _toDisplayString(_ctx.i18n.t("robots.description")), 1),
            _createVNode("div", _hoisted_11, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.robot.description,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.robot.description = $event)),
                id: "description",
                type: "text",
                disabled: true
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_12, [
            _createVNode("label", _hoisted_13, _toDisplayString(_ctx.i18n.t("robots.generation")), 1),
            _createVNode("div", _hoisted_14, [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.gener_model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.gener_model = $event)),
                options: _ctx.gener_models,
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot'))
              }, null, 8, ["modelValue", "options", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_15, [
            _createVNode("label", _hoisted_16, _toDisplayString(_ctx.i18n.t("robots.testing_market")), 1),
            _createVNode("div", _hoisted_17, [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.robot.training_market,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.robot.training_market = $event)),
                options: _ctx.markets,
                optionLabel: "longName",
                optionValue: "id",
                placeholder: "Sélectionner le marché sur lequel entraîner le robot",
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot'))
              }, null, 8, ["modelValue", "options", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_18, [
            _createVNode("label", _hoisted_19, _toDisplayString(_ctx.i18n.t("robots.testing_date_deb")), 1),
            _createVNode("div", _hoisted_20, [
              _createVNode(_component_Calendar, {
                modelValue: _ctx.robot.training_date_debut,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.robot.training_date_debut = $event)),
                showTime: false,
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot'))
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _createVNode("div", _hoisted_21, [
              _createVNode(_component_Button, {
                onClick: _ctx.dates_to_today,
                label: "--> to today",
                class: "p-button-secondary",
                icon: "pi pi-calendar",
                iconPos: "right"
              }, null, 8, ["onClick"])
            ])
          ]),
          _createVNode("div", _hoisted_22, [
            _createVNode("label", _hoisted_23, _toDisplayString(_ctx.i18n.t("robots.testing_date_fin")), 1),
            _createVNode("div", _hoisted_24, [
              _createVNode(_component_Calendar, {
                modelValue: _ctx.robot.training_date_fin,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.robot.training_date_fin = $event)),
                showTime: false,
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot'))
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_25, [
            _createVNode("label", _hoisted_26, _toDisplayString(_ctx.i18n.t("robots.test_result")), 1),
            _createVNode("div", _hoisted_27, [
              _createVNode(_component_Textarea, {
                modelValue: _ctx.result,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.result = $event)),
                autoResize: true,
                rows: "10",
                cols: "30",
                id: "test_result",
                disabled: true,
                style: {"font-family":"monospace","color":"#007bff"}
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          onClick: _ctx.test,
          label: _ctx.i18n.t('robots.launch_test'),
          class: "p-button-secondary",
          icon: "pi pi-chart-line",
          iconPos: "right"
        }, null, 8, ["onClick", "label"])
      ]),
      _: 1
    })
  ]))
}