import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "comptes-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_Button = _resolveComponent("Button")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, { value: _ctx.comptes }, {
      default: _withCtx(() => [
        (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              field: "id",
              header: _ctx.i18n.t('comptes.id')
            }, null, 8, ["header"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.i18n.t('comptes.name')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "exchange.name",
          header: _ctx.i18n.t('comptes.exchange')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "market.name",
          header: _ctx.i18n.t('comptes.market')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "robot.name",
          header: _ctx.i18n.t('comptes.robot')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "generation",
          header: _ctx.i18n.t('robots.generation')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.i18n.t('comptes.trading_actif'),
          style: {"width":"150px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_InputSwitch, {
              modelValue: slotProps.data.trading_actif,
              "onUpdate:modelValue": ($event: any) => (slotProps.data.trading_actif = $event),
              disabled: true
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.i18n.t('comptes.can_buy'),
          style: {"width":"150px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_InputSwitch, {
              modelValue: slotProps.data.can_buy,
              "onUpdate:modelValue": ($event: any) => (slotProps.data.can_buy = $event),
              disabled: true
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.i18n.t('comptes.can_sell'),
          style: {"width":"150px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_InputSwitch, {
              modelValue: slotProps.data.can_sell,
              "onUpdate:modelValue": ($event: any) => (slotProps.data.can_sell = $event),
              disabled: true
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: "",
          style: {"width":"50px"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_router_link, {
              to: {
              name: 'CompteDetailPage',
              params: { compteId: slotProps.data.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: _ctx.i18n.t('app.open'),
                  class: "p-button-success",
                  icon: "pi pi-folder-open",
                  iconPos: "right"
                }, null, 8, ["label"])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_router_link, { to: {
        name: 'CompteDetailPage',
        params: { compteId: 'notSet' },
      } }, {
      default: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.i18n.t('app.ajouter')
        }, null, 8, ["label"])
      ]),
      _: 1
    })
  ]))
}