import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_AppLayout = _resolveComponent("AppLayout")

  return (_openBlock(), _createBlock("div", {
    id: "app",
    class: _ctx.mode
  }, [
    _createVNode(_component_AppLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, { include: "ActualitesPage" }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1024))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}