import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "robot-form" }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "p-field p-grid"
}
const _hoisted_4 = {
  for: "id",
  class: "p-col-4"
}
const _hoisted_5 = { class: "p-col-8" }
const _hoisted_6 = { class: "p-field p-grid" }
const _hoisted_7 = {
  for: "name",
  class: "p-col-4"
}
const _hoisted_8 = { class: "p-col-8" }
const _hoisted_9 = { class: "p-field p-grid" }
const _hoisted_10 = {
  for: "description",
  class: "p-col-4"
}
const _hoisted_11 = { class: "p-col-8" }
const _hoisted_12 = { class: "p-field p-grid" }
const _hoisted_13 = {
  for: "training_market",
  class: "p-col-4"
}
const _hoisted_14 = { class: "p-col-8" }
const _hoisted_15 = { class: "p-field p-grid" }
const _hoisted_16 = {
  for: "training_date_deb",
  class: "p-col-4"
}
const _hoisted_17 = { class: "p-col-8" }
const _hoisted_18 = { class: "p-field p-grid" }
const _hoisted_19 = {
  for: "training_date_fin",
  class: "p-col-4"
}
const _hoisted_20 = { class: "p-col-8" }
const _hoisted_21 = { class: "p-field p-grid" }
const _hoisted_22 = {
  for: "valid_date_fin",
  class: "p-col-4"
}
const _hoisted_23 = { class: "p-col-8" }
const _hoisted_24 = {
  key: 1,
  class: "p-field p-grid"
}
const _hoisted_25 = {
  for: "params",
  class: "p-col-4"
}
const _hoisted_26 = { class: "p-col-8" }
const _hoisted_27 = {
  key: 2,
  class: "p-field p-grid"
}
const _hoisted_28 = {
  for: "config_neat",
  class: "p-col-4"
}
const _hoisted_29 = { class: "p-col-8" }
const _hoisted_30 = { class: "p-field p-grid" }
const _hoisted_31 = {
  for: "status",
  class: "p-col-4"
}
const _hoisted_32 = { class: "p-col-8" }
const _hoisted_33 = { class: "p-field p-grid" }
const _hoisted_34 = {
  for: "status",
  class: "p-col-4"
}
const _hoisted_35 = { class: "p-col-8" }
const _hoisted_36 = { class: "p-inputgroup" }
const _hoisted_37 = { class: "p-fluid" }
const _hoisted_38 = {
  key: 0,
  class: "p-field p-grid"
}
const _hoisted_39 = {
  for: "id",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_40 = { class: "p-col-12 p-md-10" }
const _hoisted_41 = { class: "p-field p-grid" }
const _hoisted_42 = {
  for: "name",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_43 = { class: "p-col-9 p-md-9" }
const _hoisted_44 = { class: "p-col-1 p-md-1" }
const _hoisted_45 = { class: "p-field p-grid" }
const _hoisted_46 = {
  for: "code",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_47 = { class: "p-col-12 p-md-10" }
const _hoisted_48 = { class: "p-field p-grid" }
const _hoisted_49 = {
  for: "description",
  class: "p-col-12 p-mb-2 p-md-2 p-mb-md-0"
}
const _hoisted_50 = { class: "p-col-12 p-md-10" }
const _hoisted_51 = {
  key: 3,
  class: "p-field p-grid"
}
const _hoisted_52 = {
  for: "no_gen_en_cours",
  class: "p-col-4"
}
const _hoisted_53 = { class: "p-col-8" }
const _hoisted_54 = {
  key: 4,
  class: "p-field p-grid"
}
const _hoisted_55 = {
  for: "training_result",
  class: "p-col-4"
}
const _hoisted_56 = { class: "p-col-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Textarea = _resolveComponent("Textarea")
  const _component_Button = _resolveComponent("Button")
  const _component_Card = _resolveComponent("Card")
  const _component_ProgressBar = _resolveComponent("ProgressBar")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode("label", _hoisted_4, [
                  _createVNode(_component_font_awesome_icon, { icon: "key" })
                ]),
                _createVNode("div", _hoisted_5, [
                  _createVNode(_component_InputText, {
                    disabled: "",
                    modelValue: _ctx.robot.id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.robot.id = $event)),
                    id: "id",
                    type: "text"
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_6, [
            _createVNode("label", _hoisted_7, _toDisplayString(_ctx.i18n.t("robots.name")), 1),
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.robot.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.robot.name = $event)),
                id: "name",
                type: "text",
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot'))
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_9, [
            _createVNode("label", _hoisted_10, _toDisplayString(_ctx.i18n.t("robots.description")), 1),
            _createVNode("div", _hoisted_11, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.robot.description,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.robot.description = $event)),
                id: "description",
                type: "text",
                spellcheck: "false",
                disabled: !_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_12, [
            _createVNode("label", _hoisted_13, _toDisplayString(_ctx.i18n.t("robots.training_market")), 1),
            _createVNode("div", _hoisted_14, [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.robot.training_market,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.robot.training_market = $event)),
                options: _ctx.markets,
                optionLabel: "longName",
                optionValue: "id",
                placeholder: "Sélectionner le marché sur lequel entraîner le robot",
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')) || _ctx.robot.status != 'Nouveau'
              }, null, 8, ["modelValue", "options", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_15, [
            _createVNode("label", _hoisted_16, _toDisplayString(_ctx.i18n.t("robots.training_date_deb")), 1),
            _createVNode("div", _hoisted_17, [
              _createVNode(_component_Calendar, {
                modelValue: _ctx.robot.training_date_debut,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.robot.training_date_debut = $event)),
                showTime: false,
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')) || _ctx.robot.status != 'Nouveau'
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_18, [
            _createVNode("label", _hoisted_19, _toDisplayString(_ctx.i18n.t("robots.training_date_fin")), 1),
            _createVNode("div", _hoisted_20, [
              _createVNode(_component_Calendar, {
                modelValue: _ctx.robot.training_date_fin,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.robot.training_date_fin = $event)),
                showTime: false,
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')) || _ctx.robot.status != 'Nouveau'
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createVNode("div", _hoisted_21, [
            _createVNode("label", _hoisted_22, _toDisplayString(_ctx.i18n.t("robots.valid_date_fin")), 1),
            _createVNode("div", _hoisted_23, [
              _createVNode(_component_Calendar, {
                modelValue: _ctx.robot.valid_date_fin,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.robot.valid_date_fin = $event)),
                showTime: false,
                disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')) || _ctx.robot.status != 'Nouveau'
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          (_ctx.robot.id != 'notSet')
            ? (_openBlock(), _createBlock("div", _hoisted_24, [
                _createVNode("label", _hoisted_25, _toDisplayString(_ctx.i18n.t("robots.params")), 1),
                _createVNode("div", _hoisted_26, [
                  _createVNode(_component_Textarea, {
                    modelValue: _ctx.robot.params,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.robot.params = $event)),
                    autoResize: true,
                    rows: "5",
                    cols: "30",
                    id: "params",
                    spellcheck: "false",
                    disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')) || _ctx.robot.status != 'Nouveau',
                    style: {"font-family":"monospace","color":"#007bff"}
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.robot.id != 'notSet')
            ? (_openBlock(), _createBlock("div", _hoisted_27, [
                _createVNode("label", _hoisted_28, _toDisplayString(_ctx.i18n.t("robots.config_neat")), 1),
                _createVNode("div", _hoisted_29, [
                  _createVNode(_component_Textarea, {
                    modelValue: _ctx.robot.config_neat,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.robot.config_neat = $event)),
                    autoResize: false,
                    rows: "20",
                    cols: "30",
                    id: "config_neat",
                    spellcheck: "false",
                    disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')) || _ctx.robot.status != 'Nouveau',
                    style: {"font-family":"monospace","color":"#007bff"}
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_30, [
            _createVNode("label", _hoisted_31, _toDisplayString(_ctx.i18n.t("robots.status")), 1),
            _createVNode("div", _hoisted_32, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.robot.status,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.robot.status = $event)),
                id: "status",
                type: "text",
                spellcheck: "false",
                disabled: true
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_33, [
            _createVNode("label", _hoisted_34, _toDisplayString(_ctx.i18n.t("robots.features")), 1),
            _createVNode("div", _hoisted_35, [
              _createVNode("div", _hoisted_36, [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.feature,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.feature = $event)),
                  options: _ctx.features,
                  optionLabel: "name",
                  disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot')) || _ctx.robot.status != 'Nouveau'
                }, null, 8, ["modelValue", "options", "disabled"]),
                _createVNode(_component_Button, {
                  icon: "pi pi-plus",
                  class: "p-button-warning",
                  onClick: _ctx.addFeature,
                  disabled: !_ctx.feature
                }, null, 8, ["onClick", "disabled"])
              ])
            ])
          ]),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.robotFeatures, (robotFeature) => {
            return (_openBlock(), _createBlock("div", {
              key: robotFeature.id
            }, [
              _createVNode(_component_Card, null, {
                content: _withCtx(() => [
                  _createVNode("div", _hoisted_37, [
                    (_ctx.appUser.is_mode_debug && _ctx.can('exec_modedebug'))
                      ? (_openBlock(), _createBlock("div", _hoisted_38, [
                          _createVNode("label", _hoisted_39, [
                            _createVNode(_component_font_awesome_icon, { icon: "key" })
                          ]),
                          _createVNode("div", _hoisted_40, [
                            _createVNode(_component_InputText, {
                              disabled: "",
                              modelValue: robotFeature.id,
                              "onUpdate:modelValue": ($event: any) => (robotFeature.id = $event),
                              id: "id",
                              type: "text"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode("div", _hoisted_41, [
                      _createVNode("label", _hoisted_42, _toDisplayString(_ctx.i18n.t("features.name")), 1),
                      _createVNode("div", _hoisted_43, [
                        _createVNode(_component_InputText, {
                          modelValue: robotFeature.name,
                          "onUpdate:modelValue": ($event: any) => (robotFeature.name = $event),
                          id: "name",
                          type: "text",
                          spellcheck: "false",
                          disabled: !_ctx.can('features.add_feature') && !_ctx.can('features.change_feature')  || _ctx.robot.status != 'Nouveau'
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _createVNode("div", _hoisted_44, [
                        (_ctx.can('robots.delete_robot') && _ctx.robotId != 'notSet')
                          ? (_openBlock(), _createBlock(_component_Button, {
                              key: 0,
                              onClick: ($event: any) => (_ctx.deleteRobotFeature(robotFeature)),
                              label: _ctx.i18n.t('app.supprimer'),
                              class: "p-button-danger",
                              icon: "pi pi-trash",
                              iconPos: "right",
                              disabled: _ctx.robot.status != 'Nouveau'
                            }, null, 8, ["onClick", "label", "disabled"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createVNode("div", _hoisted_45, [
                      _createVNode("label", _hoisted_46, _toDisplayString(_ctx.i18n.t("features.code")), 1),
                      _createVNode("div", _hoisted_47, [
                        _createVNode(_component_Textarea, {
                          modelValue: robotFeature.code,
                          "onUpdate:modelValue": ($event: any) => (robotFeature.code = $event),
                          autoResize: true,
                          rows: "3",
                          cols: "30",
                          id: "code",
                          spellcheck: "false",
                          disabled: !_ctx.can('features.add_feature') && !_ctx.can('features.change_feature') || _ctx.robot.status != 'Nouveau',
                          style: {"font-family":"monospace","color":"#007bff"}
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ])
                    ]),
                    _createVNode("div", _hoisted_48, [
                      _createVNode("label", _hoisted_49, _toDisplayString(_ctx.i18n.t("features.description")), 1),
                      _createVNode("div", _hoisted_50, [
                        _createVNode(_component_InputText, {
                          modelValue: robotFeature.description,
                          "onUpdate:modelValue": ($event: any) => (robotFeature.description = $event),
                          id: "description",
                          type: "text",
                          spellcheck: "false",
                          disabled: !_ctx.can('features.add_feature') && !_ctx.can('features.change_feature') || _ctx.robot.status != 'Nouveau'
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128)),
          (_ctx.robot.id != 'notSet' && _ctx.robot.status != 'Nouveau')
            ? (_openBlock(), _createBlock("div", _hoisted_51, [
                _createVNode("label", _hoisted_52, _toDisplayString(_ctx.i18n.t("robots.no_gen_en_cours")), 1),
                _createVNode("div", _hoisted_53, [
                  _createVNode(_component_ProgressBar, {
                    value: _ctx.robot.GenerationProgressPct
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.robot.no_gen_en_cours) + " / " + _toDisplayString(JSON.parse(_ctx.robot.params)['nb_generations']), 1)
                    ]),
                    _: 1
                  }, 8, ["value"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.robot.id != 'notSet' && _ctx.robot.status != 'Nouveau')
            ? (_openBlock(), _createBlock("div", _hoisted_54, [
                _createVNode("label", _hoisted_55, _toDisplayString(_ctx.i18n.t("robots.training_result")), 1),
                _createVNode("div", _hoisted_56, [
                  _createVNode(_component_Textarea, {
                    modelValue: _ctx.robot.training_result,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.robot.training_result = $event)),
                    autoResize: true,
                    rows: "10",
                    cols: "30",
                    id: "training_result",
                    spellcheck: "false",
                    disabled: true,
                    style: {"font-family":"monospace","color":"#007bff"}
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      footer: _withCtx(() => [
        ((_ctx.can('robots.add_robot') || _ctx.can('robots.change_robot')) && _ctx.robot.status == 'Entraîné' && _ctx.robot.id != 'notSet')
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              modelValue: _ctx.gener_model,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.gener_model = $event)),
              options: _ctx.gener_models,
              disabled: (!_ctx.can('robots.add_robot') && !_ctx.can('robots.change_robot'))
            }, null, 8, ["modelValue", "options", "disabled"]))
          : _createCommentVNode("", true),
        ((_ctx.can('robots.add_robot') || _ctx.can('robots.change_robot')) && _ctx.robot.status == 'Entraîné' && _ctx.robot.id != 'notSet')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              onClick: _ctx.renderNet,
              label: _ctx.i18n.t('robots.renderNet'),
              class: "p-button-secondary",
              icon: "pi pi-chart-line",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        ((_ctx.can('robots.add_robot') || _ctx.can('robots.change_robot')) && _ctx.robot.status == 'Entraîné' && _ctx.robot.id != 'notSet')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              onClick: _ctx.renderStats,
              label: _ctx.i18n.t('robots.renderStats'),
              class: "p-button-secondary",
              icon: "pi pi-chart-line",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        ((_ctx.can('robots.add_robot') || _ctx.can('robots.change_robot')) && _ctx.robot.status == 'Entraîné' && _ctx.robot.id != 'notSet')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 3,
              onClick: _ctx.test,
              label: _ctx.i18n.t('robots.test'),
              icon: "pi pi-chart-line",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        ((_ctx.can('robots.add_robot') || _ctx.can('robots.change_robot')) && _ctx.robot.status == 'Nouveau' && _ctx.robot.id != 'notSet')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 4,
              onClick: _ctx.train,
              label: _ctx.i18n.t('robots.train'),
              class: "p-button-secondary",
              icon: "pi pi-forward",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.can('robots.add_robot') || _ctx.can('robots.change_robot'))
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 5,
              onClick: _ctx.saveRobot,
              label: _ctx.i18n.t('app.enregistrer'),
              class: "p-button-success",
              icon: "pi pi-save",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.can('robots.delete_robot') && _ctx.robotId != 'notSet')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 6,
              onClick: _ctx.deleteRobot,
              label: _ctx.i18n.t('app.supprimer'),
              class: "p-button-danger",
              icon: "pi pi-trash",
              iconPos: "right"
            }, null, 8, ["onClick", "label"]))
          : _createCommentVNode("", true),
        (_ctx.can('users.view_audit'))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 7,
              to: {
            name: 'AuditsPage',
            params: { table: 'robots_robot', id: _ctx.robot?.id },
          }
            }, {
              default: _withCtx(() => [
                (_ctx.robotId != 'notSet')
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: _ctx.i18n.t('app.audit'),
                      icon: "pi pi-file-excel",
                      iconPos: "right",
                      class: "p-button-help"
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}