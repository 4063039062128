
import { defineComponent, computed, ref } from "vue";
import DashboardLeftMenu from "@/layouts/DashboardLeftMenu.vue"
import AppUserSimulationInfo from "@/components/app/AppUserSimulationInfo.vue"
import { useI18n } from "vue-i18n";
import Breadcrumb from './breadcrumb/Breadcrumb';
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DashboardLayout",
  components: {
    DashboardLeftMenu,
    Breadcrumb,
    AppUserSimulationInfo,
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const home = {icon: 'pi pi-home', to: '/'};
    const isMenuHidden = ref(false);
    const toggleDisplayMenu = () => {
      isMenuHidden.value = !isMenuHidden.value;
    }
    
     const items = computed(() => {
      const breadcrumbItems = route.meta.breadcrumbItems as {
        label: string
        to: string
      }[];
      for (let i = 0; i < breadcrumbItems.length; i++) {
        breadcrumbItems[i].label = i18n.t(breadcrumbItems[i].label);
      }
      return breadcrumbItems;
    });

    return {
      i18n,
      home,
      items,
      isMenuHidden,
      toggleDisplayMenu,
    };
  },
});
