import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "feature-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureForm = _resolveComponent("FeatureForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_FeatureForm, { featureId: _ctx.featureId }, null, 8, ["featureId"])
  ]))
}