
import { defineComponent, ref, computed } from "vue";
import Feature from "@/models/features/Feature";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import FeaturesList from "@/components/features/FeaturesList.vue";

export default defineComponent({
  name: "FeaturesPage",
  components: {
    Card,
    FeaturesList,
  },
  setup() {
    const i18n = useI18n();
    const Features = ref<Feature[]>();

    return {
      Features,
      i18n,
    };
  },
});
