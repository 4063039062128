import Utilisateur from '@/models/utilisateurs/Utilisateur'
import { HttpClient } from '@/http-client'

class UtilisateursApiClient {
    urls = {
        getUtilisateurs: process.env.VUE_APP_API_URL + '/api/users',
        getUtilisateur: process.env.VUE_APP_API_URL + '/api/users/$(utilisateurId)',
        postUtilisateur: process.env.VUE_APP_API_URL + '/api/users',
        putUtilisateur: process.env.VUE_APP_API_URL + '/api/users/$(utilisateurId)',
        deleteUtilisateur: process.env.VUE_APP_API_URL + '/api/users/$(utilisateurId)'
    }

    getUtilisateurs(apiFilters?: any): Promise<Utilisateur[]> {
        const getParameters = {
            url: this.urls.getUtilisateurs,
            requiresToken: false,
            filters: apiFilters
        }
        return HttpClient.get<Utilisateur[]>(getParameters)
    }

    getUtilisateur(utilisateur: Utilisateur): Promise<Utilisateur> {
        const getParameters = {
            url: this.urls.getUtilisateur.replace('$(utilisateurId)', utilisateur.id),
            requiresToken: false,
        }
        return HttpClient.get<Utilisateur>(getParameters)
    }

    sendUtilisateur(utilisateur: Utilisateur): Promise<Utilisateur> {
        if (utilisateur.id != 'notSet') {
            const getParameters = {
                url: this.urls.putUtilisateur.replace('$(utilisateurId)', utilisateur.id),
                requiresToken: false,
                payload: utilisateur,
            }
            return HttpClient.put<Utilisateur>(getParameters)
        } else {
            const getParameters = {
                url: this.urls.postUtilisateur.replace('$(utilisateurId)', utilisateur.id),
                requiresToken: false,
                payload: utilisateur,
            }
            return HttpClient.post<Utilisateur>(getParameters)
        }
    }

    deleteUtilisateur(utilisateur: Utilisateur): Promise<Utilisateur> {
        const getParameters = {
            url: this.urls.deleteUtilisateur.replace('$(utilisateurId)', utilisateur.id),
            requiresToken: false,
        }
        return HttpClient.delete<Utilisateur>(getParameters)
    }
}

export default new UtilisateursApiClient
