export default class Group {
    id: string;
    name: string;
    permissions: any[];
  
    constructor() {
      this.id = 'notSet';
      this.name = '';
      this.permissions = [];
    }
}